import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import BlockType from "../components/BlockType";
import ChainButton from "../components/ChainButton";

const useStyles = makeStyles(() => ({
  mainContainer: {
    "@media (min-width: 100px)": {
      maxWidth: "440px"
    },
    "@media (min-width: 768px)": {
      maxWidth: "620px"
    },
    "@media (min-width: 992px)": {
      maxWidth: "860px"
    },
    "@media (min-width: 1200px)": {
      maxWidth: "900px"
    },
    margin: "70px auto",
    width: "90%",
    borderRadius: "6px",
    boxShadow: "0 16px 24px 2px rgb(0 0 0 / 14%)",
    fontFamily: "Roboto, sans-serif"
  },
  boxContainer: {
    margin: "25px",

    "@media (min-width: 100px)": {
      margin: "25px 5px"
    },
    "@media (min-width: 768px)": {
      margin: "25px 5px"
    },
    "@media (min-width: 992px)": {
      margin: "25px 15px"
    },
    "@media (min-width: 1200px)": {
      margin: "25px"
    }
  },
  title: {
    textAlign: "center",
    textDecoration: "none",
    fontWeight: "800",
    paddingTop: "30px",
    paddingBottom: "20px",
    minHeight: "32px",
    textTransform: "capitalize",
    fontFamily: "Roboto, sans-serif",

    "@media (min-width: 100px)": {
      fontWeight: "500"
    },
    "@media (min-width: 768px)": {
      gridTemplateColumns: "repeat(2,1fr)"
    },
    "@media (min-width: 992px)": {
      fontWeight: "600"
    },
    "@media (min-width: 1200px)": {
      fontWeight: "800"
    }
  },
  paragraph: {
    textAlign: "center",
    fontWeight: "500",
    paddingBottom: "30px",
    width: "90%",
    margin: "0 auto",
    minHeight: "32px",
    fontSize: "18px",

    fontFamily: "Roboto, sans-serif",

    "@media (min-width: 100px)": {
      width: "95%"
    },
    "@media (min-width: 768px)": {
      width: "95%"
    },
    "@media (min-width: 992px)": {
      width: "90%"
    },
    "@media (min-width: 1200px)": {
      width: "90%"
    }
  },
  paragraphTwo: {
    paddingTop: "15px",
    paddingBottom: "20px",
    fontFamily: "Roboto, sans-serif"
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(4,1fr)",
    margin: "0 auto",
    width: "80%",
    gridGap: "20px",
    marginBottom: "50px",

    "@media (min-width: 100px)": {
      gridTemplateColumns: "1fr"
    },
    "@media (min-width: 768px)": {
      gridTemplateColumns: "repeat(2,1fr)"
    },
    "@media (min-width: 992px)": {
      gridTemplateColumns: "repeat(2,1fr)"
    },
    "@media (min-width: 1200px)": {
      gridTemplateColumns: "repeat(4,1fr)"
    }
  }
}));

function Stakeholders() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      <div className={classes.boxContainer}>
        <h1 className={classes.title}>&chain protocol</h1>
        <p className={classes.paragraph}>
          Building the community who shape the employability and the human
          capital
        </p>
        <div className={classes.grid}>
          <BlockType
            img="images/chainlink.svg"
            alt="chainlink"
            title="Chainlink"
            link="https://chain.link/"
          />

          <BlockType
            img="images/moralis.svg"
            alt="Moralis"
            title="Moralis"
            link="https://moralis.io/"
          />
          <BlockType
            img="images/openZeppelin.svg"
            alt="OpenZeppelin"
            title="OpenZeppelin"
            link="https://www.openzeppelin.com/"
          />
          <BlockType
            img="https://polygontechnology.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F9b15d4c0-1aca-4191-b3c7-239596f0f6ca%2FPolygon.png?table=block&id=2cd18ae4-3658-4e98-a6c5-aae56db73058&spaceId=51562dc1-1dc5-4484-bf96-2aeac848ae2f&width=250&userId=&cache=v2"
            alt="Moralis"
            title="Polygon"
            link="https://polygon.technology/"
          />
        </div>
        <p className={`${classes.paragraph} ${classes.paragraphTwo}`}>
          Be part of the future
        </p>
        <ChainButton color="primary" email="mailto:ampersand@lillup.com">
          Contact-us
        </ChainButton>
      </div>
    </div>
  );
}

export default Stakeholders;
