import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { StyledPageWrapper } from "./style";


const Layout = (props) => {
  return (
    <>
      <StyledPageWrapper>
        <Header />
        {props.children}
        <Footer />
      </StyledPageWrapper>
    </>
  );
};

export default Layout;
