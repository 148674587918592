import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core";
import { useMoralisFile, useMoralis } from "react-moralis";
import { Moralis } from "moralis";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Web3 from "web3";

import styles from "./style/buttonStyle";
import abi from "../../contract/amp_abi.json";

const useStyles = makeStyles(styles);
// var metadataValue;
export default function ButtonCreate(props) {
  const {
    title,
    img,
    description,
    ischecked,
    children,
    price,
    balance,
    amount,
    ...rest
  } = props;
  const classes = useStyles();
  // const { saveFile } = useMoralisFile();
  const { user } = useMoralis();
  const [loading, setLoading] = useState();

  const onWeb3Send = async (img) => {
    let id;
    const web3 = new Web3(Web3.givenProvider);
    // const contract = new web3.eth.Contract(abi, '0x60E379853C762eD3c50731f9B1bFa8CAD681817F');//origin
    const contract = new web3.eth.Contract(
      abi,
      "0x3A6b37201c3A96D3FB9fb8D6A759b5343a64c178"
    );

    // amount,  name,  description,  imageURI,   url
    console.log(amount, title, description, img, img);

    await contract.methods
      .createAmp(amount, title, description, img, img)
      .send({ from: user.get("ethAddress"), value: balance })
      .then((response) => {
        console.log(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // const btoa2 = function(str) {
  // 	return Buffer.from(str).toString('base64');
  // };

  const uploadMetadata = async (title, description, img) => {
    const metadata = {
      name: title,
      description: description,
      image: img,
    };

    // const file = new Moralis.File('file.json', {
    // 	base64: btoa(JSON.stringify(metadata))
    // });

    console.log(metadata);
    // await file.saveIPFS();
    // metadataValue = file.ipfs();

    // metadataValue = metadata;

    // console.log(metadataValue);
    onWeb3Send(img);
  };

  const uploadImage = async () => {
    console.log(props.cosdata[0]);
    const data = props.cosdata[0].text;

    const file = new Moralis.File(data.name, data);
    await file.saveIPFS();
    return file.ipfs();
  };

  const fetchValidation = async () => {
    setLoading(true);
    if (title && description) {
      const image = await uploadImage(img);
      uploadMetadata(title, description, image);
    }
  };

  return (
    <LoadingButton
      variant="contained"
      color="error"
      {...rest}
      loading={loading}
      loadingPosition="start"
      startIcon={<SaveIcon />}
      className={classes.button}
      onClick={() => fetchValidation()}
    >
      {children}
    </LoadingButton>
  );
}
