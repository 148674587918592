const InstagramSectionStyle = {
  sectionTitle: {
    color: "black",
    fontSize: "26px",
    fontWeight: "700",
    lineHeight: "32.19px",
    "@media(min-width: 780px)": {
      fontSize: "36px",
      lineHeight: "42.19px",
    },
  },
  linkSection: {
    position: "absolute",
    top: "0px",
    right: "0px",
  },
  mainContainer: {
    "@media (min-width: 100px)": {
      maxWidth: "440px",
    },
    "@media (min-width: 768px)": {
      maxWidth: "620px",
    },
    "@media (min-width: 992px)": {
      maxWidth: "860px",
    },
    "@media (min-width: 1200px)": {
      maxWidth: "900px",
    },
    margin: "70px auto",

    display: "grid",
    fontFamily: "Roboto, sans-serif",
    "@media (min-width: 1000px)": {
      gridTemplateColumns: "20% 80%",
      gap: "2rem",
    },
  },
};

export default InstagramSectionStyle;
