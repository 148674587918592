import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Video from "../Video";

import {
  HeadingGoesWrap,
  HeadingGoesHeading,
  BordersWrap,
  VideoSection,
  ButtonsWrap,
} from "./style";

const HeadingGoes = () => {
  return (
    <HeadingGoesWrap>
      <Container fixed>
        {/* <HeadingGoesHeading>
          <h1>TECHNOLOGY ENHANCES HUMAN EXPERIENCE</h1>
          <BordersWrap>
            <span />
            <span />
            <span />
          </BordersWrap>
        </HeadingGoesHeading> */}
        {/* <VideoSection>
          <Video />
        </VideoSection> */}
        <Grid
          container
          alignItems="center"
          rowSpacing={0}
          columnSpacing={{ xs: 1, sm: 2, lg: 8 }}
        >
          <Grid item xs={3} lg={4}>
            <Button
              target="_blank"
              href="https://lillup.gitbook.io/use-and-chain/"
              className="active"
            >
              &chain
            </Button>
          </Grid>
          <Grid item xs={6} lg={4}>
            <Button target="_blank" href="https://lillup.gitbook.io/learn/">
              AMPERSAND NFT TOKEN
            </Button>
          </Grid>
          <Grid item xs={3} lg={4}>
            <Button
              target="_blank"
              href="https://lillup.gitbook.io/f.a.q/"
              className="active"
            >
              F.A.Q
            </Button>
          </Grid>
        </Grid>
      </Container>
    </HeadingGoesWrap>
  );
};

export default HeadingGoes;
