import React, { useEffect } from "react";
import WalletSignUp from "../components/WalletSignUp";

function WalletSignUpPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <WalletSignUp />
    </>
  );
}

export default WalletSignUpPage;
