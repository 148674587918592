import React, { useEffect } from "react";
import WalletSignIn from "../components/WalletSignIn";

function WalletSignInPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <WalletSignIn />
    </>
  );
}

export default WalletSignInPage;
