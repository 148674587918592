import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import { Link } from "@reach/router";
import {
  FooterWrap,
  MainFooter,
  SubFooter,
  SocialMedia,
  FooterMainMenu,
  FooterSubMenu,
  CopyRight,
} from "./style";
import facebook from "../../assets/socialMedia/Facebook.png";
// import discord from "../../assets/socialMedia/discord.svg";
import linkedin from "../../assets/socialMedia/linkedin.svg";
import instagram from "../../assets/socialMedia/Instagram.svg";
import twitter from "../../assets/socialMedia/twitterNew.svg";

const Footer = () => {
  return (
    <FooterWrap>
      <MainFooter>
        <Container fixed>
          <Grid container alignItems="top">
            <Grid item xs={6} lg={3}>
              <SocialMedia>
                <a target="_blank" href="https://twitter.com/LillupWorkplace">
                  <img className="twitter" src={twitter} alt="Twitter" />
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/lillup"
                >
                  <img className="linkedin" src={linkedin} alt="Linkedin" />
                </a>
                {/* <Link to="#">
                  <img className="discord" src={discord} alt="Discord" />
                </Link> */}
                <a
                  target="_blank"
                  href="https://www.instagram.com/lillup_ampersand/"
                >
                  <img className="instagram" src={instagram} alt="Instagram" />
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/LillupWorkplace/"
                >
                  <img className="facebook" src={facebook} alt="Facebook" />
                </a>
              </SocialMedia>
            </Grid>
            <Grid item xs={6} lg={3}>
              <FooterMainMenu>
                <li>
                  <Link to="creator">Creator</Link>
                </li>
                <li>
                  <Link to="allTogether">All Together</Link>
                </li>
                <li>
                  <a target="_blank" href="https://mumbai.polygonscan.com/">
                    Explore
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://lillup.gitbook.io/">
                    Documentation
                  </a>
                </li>
                <li>
                  <Link to="stakeholder">Stakeholder</Link>
                </li>
                <li>
                  <a target="_blank" href="https://lillup.gitbook.io/about-us/">
                    About Us
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://lillup.medium.com/">
                    Blog
                  </a>
                </li>
              </FooterMainMenu>
            </Grid>
          </Grid>
          <Typography
            sx={{
              paddingTop: "5%",
              color: "white",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "28px",
              "@media(maxWidth: 1199.9px)": {
                fontSize: "16px",
              },
            }}
          >
            &chain protocol is deployed on green{" "}
            <a
              className="footer-bl"
              target="__blank"
              href="https://polygon.technology/"
            >
              Polygon
            </a>{" "}
            blockchain. Cloud Computing are carbon neutral.
          </Typography>
        </Container>
      </MainFooter>
      <SubFooter>
        <Grid container alignItems="center">
          <Grid className="CopyRightWrap" item xs={12} md={7} lg={8}>
            <CopyRight
              className="licenseText"
              xmlnsXlink="http://creativecommons.org/ns#"
            >
              © 2023 Lillup. All rights reserved.
            </CopyRight>
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <FooterSubMenu>
              <li>
                <a
                  target="_blank"
                  href="https://lillup.gitbook.io/terms-and-conditions-1/                   "
                >
                  Terms and Conditions
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://lillup.gitbook.io/privacy-policy/"
                >
                  Privacy Policy
                </a>
              </li>
            </FooterSubMenu>
          </Grid>
        </Grid>
      </SubFooter>
    </FooterWrap>
  );
};
export default Footer;
