import React from "react";
import { Container, Box, Grid, Typography } from "@mui/material";

const blocks = [
  { keyword: "All Knowledge", image: "All-Knowledge" },
  { keyword: "All People", image: "All-People" },
  { keyword: "All Powerfull", image: "All-Powerfull" }
];

const ResourceService = () => {
  return (
    <Box sx={{ padding: "80px 0" }}>
      <Container fixed sx={{ padding: "0 8% !important" }}>
        <Grid
          container
          alignItems="center"
          rowSpacing={{ xs: 1, sm: 2, lg: 8 }}
          columnSpacing={{ xs: 1, sm: 2, lg: 8 }}
        >
          {blocks.map((block, index) => (
            <Grid item xs={12} md={4} lg={4} key={index}>
              <Box
                sx={{
                  background: "#fff",
                  boxShadow: "0px 9px 31px rgba(202, 202, 202, 0.25)",
                  borderRadius: "10px",
                  padding: "7%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "end",
                  position: "relative",
                  "@media(max-width: 800px)": {
                    height: "200px",
                    marginBottom: "30px"
                  }
                }}
              >
                <Box
                  sx={{
                    marginBottom: "30px"
                    // position: "absolute",
                    // top: "-40px",
                    // "@media(max-width: 800px)": {
                    //   position: "unset",
                    //   marginBottom: "30px"
                    // }
                  }}
                >
                  <img src={`images/${block.image}.svg`} alt={block.keyword} />
                </Box>
                <Typography
                  sx={{
                    fontSize: "22px",
                    fontWeight: "500",
                    textAlign: "center",
                    textTransform: "upparcase",
                    color: "#5a4a42",
                    "@media (max-width: 799.9px)": {
                      fontSize: "15px"
                    }
                  }}
                >
                  {block.keyword}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default ResourceService;
