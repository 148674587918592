import React, { useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Box,
  Card,
  Grid,
  Link,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useMoralis } from "react-moralis";
import swal from "sweetalert";
import { navigate } from "@reach/router";
import LoadingButton from "@mui/lab/LoadingButton";
import BG1 from "../../assets/images/bg-1.svg";
import BG2 from "../../assets/images/bg-2.svg";
import facebook from "../../assets/socialMedia/Facebook.png";
import instagram from "../../assets/socialMedia/Instagram.svg";
import linkedin from "../../assets/socialMedia/linkedin.svg";
import twitter from "../../assets/socialMedia/twitterNew.svg";

const WalletSignIn = () => {
  const {
    login,
    isAuthenticated,
    authError,
    isAuthenticating,
    // Moralis
  } = useMoralis();
  // Form validation with formik/Yup
  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email format")
      .required("email is required"),
    password: yup.string().required("Required Password"),
  });

  const onSubmit = async (values) => {
    const email = values.email;
    const password = values.password;

    await login(email, password);

    formik.resetForm();
  };

  if (isAuthenticated) {
    navigate("/connectWallet");
  }
  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  // const { Title } = Typography;

  useEffect(() => {
    if (authError) {
      swal({
        title: authError.name,
        text: authError.message,
        icon: "warning",
        dangerMode: true,
      });
    }

    if (isAuthenticated) {
      navigate("/connectWallet");
    }
  }, [authError, isAuthenticated]);

  return (
    <>
      <Box
        sx={{
          paddingY: "10%",
          backgroundImage: `url(${BG1}), url(${BG2})`,
          backgroundSize: "cover",
        }}
      >
        <Container fixed>
          <Grid container>
            <Grid item xs={12} sm={1} md={3}></Grid>
            <Grid item xs={12} sm={10} md={6}>
              <Card
                sx={{
                  borderRadius: "20px",
                  backgroundColor: "white",
                  padding: "8%",
                  mx: "5%",
                  boxShadow: "1px 5px 8px 7px #ececec",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <img
                    style={{ height: "40px" }}
                    className="facebook"
                    src={facebook}
                    alt="Facebook"
                  />

                  <img
                    style={{ height: "40px" }}
                    className="linkedin"
                    src={linkedin}
                    alt="linkedin"
                  />

                  <img
                    style={{ height: "40px" }}
                    className="twitter"
                    src={twitter}
                    alt="twitter"
                  />

                  <img
                    style={{ width: "40px" }}
                    className="instagram"
                    src={instagram}
                    alt="Instagram"
                  />
                </Box>

                <Box sx={{ textAlign: "center", flex: "1" }}>
                  <Typography variant="h4">Sign In New</Typography>
                  <Box sx={{ marginTop: "4%" }}>
                    <Typography variant="p" sx={{ fontWeight: "500" }}>
                      Not registered yet?
                    </Typography>
                    <Link
                      href="/walletSignup"
                      sx={{
                        textDecoration: "none",
                        color: "#eb1f24",
                        fontWeight: "500",
                        marginLeft: "1%",
                      }}
                    >
                      Sign Up
                    </Link>
                  </Box>
                </Box>
                <Box component="form" onSubmit={formik.handleSubmit}>
                  <Box>
                    <Typography
                      align="left"
                      sx={{
                        marginTop: "25px",
                        fontSize: {
                          md: "16px",
                          lg: "18px",
                          xs: "16px",
                          sm: "16px",
                        },
                        fontWeight: "bolder",
                        marginBottom: "2%",
                      }}
                    >
                      Email
                    </Typography>

                    <TextField
                      name="email"
                      formik={formik}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      variant="standard"
                      autoComplete="off"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      fullWidth
                      type="email"
                      placeholder="Enter Email"
                      sx={{
                        ".MuiInput-root": {
                          borderRadius: "8px",
                          border: "1px solid gray",
                          px: "20px",
                          py: "6px",
                        },
                        ".Mui-error": {
                          fontSize: "16px",
                        },
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      align="left"
                      sx={{
                        marginTop: "10%",
                        fontSize: {
                          md: "16px",
                          lg: "18px",
                          xs: "16px",
                          sm: "16px",
                        },
                        fontWeight: "bolder",
                        marginBottom: "2%",
                      }}
                    >
                      Password
                    </Typography>

                    <TextField
                      name="password"
                      formik={formik}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      variant="standard"
                      autoComplete="off"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      fullWidth
                      type="password"
                      placeholder="Password"
                      sx={{
                        ".MuiInput-root": {
                          borderRadius: "8px",
                          border: "1px solid gray",
                          px: "20px",
                          py: "6px",
                        },
                        ".Mui-error": {
                          fontSize: "16px",
                        },
                      }}
                    />
                    <LoadingButton
                      loading={isAuthenticating}
                      type="submit"
                      variant="contained"
                      sx={{
                        marginTop: "10%",
                        fontSize: {
                          lg: "20px",
                          md: "20px",
                          sm: "20px",
                          xs: "12px",
                        },
                        borderRadius: "8px",

                        height: {
                          lg: "50px",
                          md: "50px",
                          sm: "50px",
                          xs: "40px",
                        },
                        color: "white",
                        backgroundColor: "#eb1f24",
                      }}
                      fullWidth
                    >
                      Sign In
                    </LoadingButton>
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} sm={1} md={3}></Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default WalletSignIn;
