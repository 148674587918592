import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useMoralis } from 'react-moralis';

import BodyProfile from './BodyProfile';
import HeaderProfile from './HeaderProfile';
import SocialProfile from './SocialProfile';
import WalletProfile from './WalletProfile';

import profileFormStyle from './style/profileFormStyle';

const useStyles = makeStyles(profileFormStyle);

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	backgroundColor: '#e52d27',
	padding: '20px',
	margin: '10px 0',
	color: 'white',
	borderWidth: '2',
	borderRadius: '2',
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	height: '170px'
};

export default function ProfileForm() {
	const classes = useStyles();
	const { Moralis } = useMoralis();
	const currentuser = Moralis.User.current();
	const [ formData, setFormData ] = useState(
		{
			full_name: currentuser?.full_name || "",
			email: currentuser?.email || "",
			position: currentuser?.position || "",
			company: currentuser?.company || true,
			name: currentuser?.name || "",
			address: currentuser?.address || "",
			website: currentuser?.website || "",
			twitter: currentuser?.twitter || "",
			instagram: currentuser?.instagram || "",
			facebook: currentuser?.facebook || "",
			linkedin: currentuser?.linkedin || ""
		}
	);

	return (
		<div className={classes.formContainer}>
			<HeaderProfile />
			<BodyProfile setFormData={setFormData} formData={formData} />
			<SocialProfile setFormData={setFormData} formData={formData} />
			<WalletProfile />
		</div>
	);
}
