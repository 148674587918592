import styled from "styled-components";

export const FooterWrap = styled.footer``;

export const MainFooter = styled.div`
  background-color: #333c41;
  padding: 36px 3.3%;
  h3 {
    /* font-family: SF Pro Display; */
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: rgba(255, 255, 255, 0.78);
    margin-bottom: 12px;
    margin-top: 0;
  }

  .footer-bl {
    color: #fff;

    &:hover {
      color: #eb1f24;
    }
  }
`;

export const SubFooter = styled.div`
  background-color: #eb1f24;
  padding: 17px 4.7%;
  .licenseText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    color: rgba(255, 255, 255, 0.77);
    margin: 0;
    a {
      display: inline;
      color: rgba(255, 255, 255, 0.77);
      margin-left: 8px;
      &:hover {
        color: #333c41;
      }
      img {
        height: 22px !important;
        margin-left: 3px;
        vertical-align: text-bottom;
      }
    }
  }
  @media (max-width: 899.9px) {
    .CopyRightWrap {
      text-align: center;
    }
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 27px;
  gap: 14px;
  height: 30px;

  a {
    display: block;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    .instagram,
    .facebook {
      height: 27px;
    }

    img {
      width: 30px;
      height: 30px;
      transition: transform 0.6s ease;
      object-fit: cover;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      object-fit: contain;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
`;

export const FooterMainMenu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 12px;
    a {
      text-decoration: none;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      color: #ffffff;
      transition: all 0.2s linear;
      &:hover {
        color: #eb1f24;
      }
    }
  }
`;

export const CopyRight = styled.span`
  /* font-family: "SF Pro Display"; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.77);
`;

export const FooterSubMenu = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 0;
  padding: 0;
  li {
    /* font-family: "SF Pro Display"; */
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: rgba(255, 255, 255, 0.77);
    a {
      color: rgba(255, 255, 255, 0.77);
      text-decoration: none;
      margin-right: 20px;
    }
  }
  @media (max-width: 899.9px) {
    justify-content: center;
    /* margin-top: 20px; */
  }
`;
