import styled from "styled-components";

export const NewsLetter = styled.div`
  width: 820px;
  height: 72px;
  background: #f5f5f5;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 6px 5px;
  margin: 64px auto 27px;
  input {
    padding-left: 10px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
  }
  @media (max-width: 1199.9px) {
    width: 100%;
    height: 60px;
  }
`;

export const Form = styled.form`
  .MuiInputBase-root {
    width: 100%;
    height: 60px;
    &:before {
      content: unset;
    }
    &:after {
      content: unset;
    }
  }
  .submit {
    width: 100%;
    height: 60px;
    background: #eb1f24;
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #ffffff;
    &:hover {
      background-color: #eb1f24;
    }
  }

  .MuiFormGroup-root {
    margin-top: 20px;
  }

  @media (max-width: 1199.9px) {
    .MuiInputBase-root {
      height: 40px;
    }

    .submit {
      height: 40px;
      border-radius: 8px;
      font-size: 16px;
      line-height: 20px;
    }
  }
`;
