import React, { useEffect } from 'react'
import Layout from './layout/Layout'
import { Router, createHistory, LocationProvider, useLocation } from '@reach/router'

import HomePage from './pages/Home'
import Creator from './pages/Creator'
import Resource from './pages/Resource'
// import Learner from "./pages/Learner";
import Stakeholders from './pages/Stakeholders'
import WalletSignUpPage from './pages/WalletSignUpPage'
import WalletSignInPage from './pages/WalletSignInPage'
import ConnectWallet from './pages/ConnectWallet'
import Status from './pages/Status'

import Create from './pages/Create'
import Profile from './pages/Profile'
import TotalValueAssets from './pages/TotalValueAssets'
import PortFolio from './pages/PortFolio'
import Undefined from './pages/Undefined'
import AllTogether from './pages/AllTogether'
import { AppProvider } from './utils/context'
import './styles/style.scss'
import { Redirect } from '@reach/router'
import FbPixel from 'react-facebook-pixel'

const Pages = () => {
  const routeLocation = useLocation()

  // facebook pixel
  useEffect(() => {
    FbPixel.pageView()
  }, [])

  useEffect(() => {
    FbPixel.pageView()
  }, [routeLocation])

  return (
    <Router>
      <HomePage path="/" />
      <Creator path="/creator" />
      {/* <Learner path="/learner" /> */}
      {/* <AllTogether path="/resources" /> */}
      <Resource path="/allTogether" />
      <Stakeholders path="/stakeholder" />
      <WalletSignUpPage path="/walletSignup" />
      <WalletSignInPage path="/walletSignin" />
      <ConnectWallet path="/connectWallet" />
      {/* <Status path="/status/:page" /> */}

      <Create path="/creator/mint" />
      <Profile path="/creator/account" />
      <TotalValueAssets path="/creator/totalValue" />
      <PortFolio path="/creator/portFolio" />

      <Undefined path="*" />
    </Router>
  )
}
export default function App() {
  useEffect(() => {
    // facebook pixel init
    FbPixel.init('523619446368357', {}, { autoConfig: true, debug: false })

    // facebook like button
    const fbRoot = document.createElement('div')

    fbRoot.id = 'fb-root'
    document.body.appendChild(fbRoot)

    const fbLikeScript = document.createElement('script')

    fbLikeScript.src =
      'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v15.0&appId=528207179155218&autoLogAppEvents=1'
    fbLikeScript.async = true
    fbLikeScript.defer = true
    fbLikeScript.crossOrigin = 'anonymous'
    fbLikeScript.nonce = 'sMqIcuqH'

    document.body.appendChild(fbLikeScript)

    const script = document.createElement('script')

    script.src = 'https://secure.intelligent-data-247.com/js/260711.js'
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])
  // let history = createHistory(window);

  const location = window?.location
  const lockedPages = ['linkedin', 'instagram', 'twitter']

  function lockedEndpoints(pathname, hash) {
    location.hash = hash
    location.pathname = pathname
  }

  useEffect(() => {
    if (location.pathname === '/linkedin') {
      lockedEndpoints('/', 'linkedin')
    } else if (location.pathname === '/instagram') {
      lockedEndpoints('/', 'instagram')
    } else if (location.pathname === '/twitter') {
      lockedEndpoints('/', 'twitter')
    }
  }, [location])

  return (
    <>
      <LocationProvider>
        {!lockedPages.includes(location.pathname.split('/')[1]) && (
          <AppProvider>
            <Layout>
              <Pages />
            </Layout>
          </AppProvider>
        )}
      </LocationProvider>
    </>
  )
}



