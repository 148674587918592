import React, { useEffect } from "react";
// import BasicModal from "../components/BasicModal";
// import EmptyComponent from "../components/EmptyComponent/EmptyComponent";
// import CreateHolder from "../components/CreateHolder/CreateHolder";
import LearnerExperience from "../components/LearnerExperience/LearnerExperience";
function Status() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <BasicModal /> */}
      <LearnerExperience />
    </>
  );
}

export default Status;
