import React from "react";
import Button from "@mui/material/Button";
import { Container, Box, Grid, Typography } from "@mui/material";

const blocks = [
  { keyword: "Future of Work", image: "future_work" },
  { keyword: "Curricula", image: "curricula" },
  { keyword: "Human Capital", image: "human_capital" },
  { keyword: "&chain protocol", image: "chain_protocol" },
  { keyword: "Employability", image: "employability" },
  { keyword: "Elearning", image: "elearning" },
  { keyword: "Creator", image: "creator" },
  { keyword: "Enterprise", image: "enterprice" },
  { keyword: "Individual", image: "individual" },
];

const DemoRequest = () => {
  return (
    <Box>
      <Container fixed sx={{ padding: "0 8% !important" }}>
        <Grid
          container
          alignItems="center"
          rowSpacing={{ xs: 1, sm: 2, lg: 8 }}
          columnSpacing={{ xs: 1, sm: 2, lg: 8 }}
        >
          {blocks.map((block, index) => (
            <Grid item xs={6} lg={4} key={index}>
              <Box
                sx={{
                  background: "#fff",
                  boxShadow: "0px 9px 31px rgba(202, 202, 202, 0.25)",
                  borderRadius: "10px",
                  padding: "7%",
                  height: "auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  maxWidth: "323px",
                  "@media(maxWidth: 800px)": {
                    height: "auto",
                  },
                }}
              >
                <img src={`images/${block.image}.svg`} alt={block.keyword} />

                {/* <Box
                  variant="span"
                  sx={{
                    width: "67px",
                    height: "67px",
                    backgroundColor: "rgba(196, 196, 196, 0.25)",
                    borderRadius: "50%",
                    margin: "0 auto",
                    marginBottom: "7%"
                  }}
                ></Box> */}
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: "600",
                    textAlign: "center",
                    color: "#5a4a42",
                    "@media (max-width: 799.9px)": {
                      fontSize: "15px",
                    },
                  }}
                >
                  {block.keyword}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ textAlign: "center", marginTop: "30px" }}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            rowSpacing={{ xs: 1, sm: 2, lg: 8 }}
            columnSpacing={{ xs: 1, sm: 2, lg: 8 }}
          >
            <Grid item xs={12} lg={6}>
              <Button
                href="https://calendly.com/ludovic-lillup/lillup"
                target="_blank"
                sx={{
                  backgroundColor: "#EB1F24",
                  color: "white",
                  borderRadius: "10px",
                  width: "100%",
                  height: "64px",
                  border: "1px solid #eb1f24",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "22px",
                  lineHeight: "26px",
                }}
              >
                REQUEST A DEMO
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default DemoRequest;
