import React, { useState } from "react";
import PropTypes from "prop-types";

const AppContext = React.createContext({});

const AppProvider = ({ children }) => {
  const [noBanner, setNoBanner] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [locationPath, setLocationPath] = useState("/");
  const [modalClicked, setModalClicked] = useState(true);

  return (
    <AppContext.Provider
      value={{
        noBanner,
        setNoBanner,
        isOpen,
        setIsOpen,
        locationPath,
        setLocationPath,
        modalClicked,
        setModalClicked,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.object,
};

export { AppContext, AppProvider };
