import React, { useState, useEffect } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core';

import TotalValueForm from '../TotalValueAssetsComponents/TotalValueAssetsForm';
import totalValueAssetsHolderStyle from '../TotalValueAssetsComponents/style/totalValueAssetsHolderStyle';

import Navigate from '../SideNavigate/Navigate';

const useStyles = makeStyles(totalValueAssetsHolderStyle);

function TotalValueAssets() {
	const classes = useStyles();
	return (
		<div className={classes.mainContainer}>
			<Navigate />
			<TotalValueForm />
		</div>
	);
}

export default TotalValueAssets;
