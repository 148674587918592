import React, { useState } from "react";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Button,
} from "@mui/material";
import { useMoralis, useNFTBalances } from "react-moralis";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import NFTTokenList from "./NFTTokenList";
import NFTTokenDetail from "./NFTTokenDetail";
import SocialMediaList from "./SocialMediaList";
import EmbedGroup from "./EmbedGroup";
// import QRCode from "react-qr-code";
import learnerExperienceStyle from "./style";

// import imageBack from "assets/images/lillup-learner-experience-desktop-13.png";
import wallet_img from "../../assets/images/wallet_avatar.png";
import token_img from "../../assets/images/token_avatar.png";

const useStyles = makeStyles(learnerExperienceStyle);

const useFormStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(0),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "100%",
    height: "55px",
  },
  borderProp: {
    background: "#FFFFFF",
    borderRadius: "0.5vw",
  },
}));

const LearnerExperience = () => {
  const pageClasses = useStyles();

  const [nftDatas, setNFTDatas] = useState({
    renderCount: 0,
    totalCount: 0,
    renderMetadatas: [],
    totalMetadatas: [],
    pageCount: 0,
    pageIndex: 0,
  });

  const [selectedMetadata, setSelectedMetadata] = useState({
    title: "",
    desc: "",
    image: "",
    row: 1,
    col: 1,
    globalIndex: 0,
    walletAddress: "",
    tokenId: "",
  });

  const [nftNotes, setNFTNotes] = useState("You don't have any NFT ampersand");
  const [selectedWalletAddress, setSelectedWalletAddress] = useState(
    "0xc2C1c4491a4ed8C3112e5207EF3bD7DA67c3c1ba"
  );
  const [selectedTokenID, setSelectedTokenID] = useState("");
  const [tokenLink, setTokenLink] = useState("https://www.w3schools.com/");

  const { authenticate, isAuthenticated, logout, user, auth } = useMoralis();
  const { getNFTBalances, data, error, isLoading, isFetching } =
    useNFTBalances();

  const onNextPage = function () {
    if (nftDatas.pageCount < 1) {
      return;
    }
    if (nftDatas.pageIndex >= nftDatas.pageCount - 1) {
      return;
    }

    /*
        + ------------------------------------------ +
        + reset states
        + ------------------------------------------ +
        */
    setNFTDatas((previousState) => {
      return {
        ...previousState,
        renderCount: 0,
        renderMetadatas: [],
      };
    });

    var pageIndex = nftDatas.pageIndex;
    pageIndex++;
    var index = 0;

    /*
        + ------------------------------------------ +
        + get render metadatas
        + ------------------------------------------ +
        */
    var renderMetadatas = [];
    var renderCount = 0;
    var startIndex = pageIndex * 12;
    var endIndex = startIndex + 12;
    if (endIndex > nftDatas.totalCount) {
      endIndex = nftDatas.totalCount;
    }
    for (index = startIndex; index < endIndex; index++) {
      renderMetadatas.push(nftDatas.totalMetadatas[index]);
      renderCount++;
    }

    /*
        + ------------------------------------------ +
        + update states
        + ------------------------------------------ +
        */
    setNFTDatas((previousState) => {
      return {
        ...previousState,
        renderCount: renderCount,
        renderMetadatas: renderMetadatas,
        pageIndex: pageIndex,
      };
    });

    setNFTNotes(
      "Page Info : [" + (pageIndex + 1) + "/" + nftDatas.pageCount + "]"
    );
    // setSelectedWalletAddress("");
    setSelectedTokenID("");
  };

  const onPrevPage = function () {
    if (nftDatas.pageCount < 1) {
      return;
    }
    if (nftDatas.pageIndex <= 0) {
      return;
    }

    /*
        + ------------------------------------------ +
        + reset states
        + ------------------------------------------ +
        */
    setNFTDatas((previousState) => {
      return {
        ...previousState,
        renderCount: 0,
        renderMetadatas: [],
      };
    });

    var pageIndex = nftDatas.pageIndex;
    pageIndex--;
    var index = 0;

    /*
        + ------------------------------------------ +
        + get render metadatas
        + ------------------------------------------ +
        */
    var renderMetadatas = [];
    var renderCount = 0;
    var startIndex = pageIndex * 12;
    var endIndex = startIndex + 12;
    if (endIndex > nftDatas.totalCount) {
      endIndex = nftDatas.totalCount;
    }
    for (index = startIndex; index < endIndex; index++) {
      renderMetadatas.push(nftDatas.totalMetadatas[index]);
      renderCount++;
    }

    /*
        + ------------------------------------------ +
        + update states
        + ------------------------------------------ +
        */
    setNFTDatas((previousState) => {
      return {
        ...previousState,
        renderCount: renderCount,
        renderMetadatas: renderMetadatas,
        pageIndex: pageIndex,
      };
    });

    setNFTNotes(
      "Page Info : [" + (pageIndex + 1) + "/" + nftDatas.pageCount + "]"
    );
    // setSelectedWalletAddress("");
    setSelectedTokenID("");
  };

  const onBackToPortfolio = function () {
    document.getElementById("nft-token-list-wrapper").style.display = "block";
    document.getElementById("nft-token-detail-wrapper").style.display = "none";
  };

  const onNFTTokenSelected = function (index) {
    if (nftDatas.pageCount < 1) {
      return;
    }
    if (index < 0 || index >= nftDatas.totalCount) {
      return;
    }

    var metadata = { ...nftDatas.totalMetadatas[index] };
    setSelectedTokenID(metadata.tokenId);

    document.getElementById("nft-token-list-wrapper").style.display = "none";
    document.getElementById("nft-token-detail-wrapper").style.display = "block";

    setSelectedMetadata((previousState) => {
      return {
        ...previousState,
        title: metadata.title,
        desc: metadata.desc,
        image: metadata.image,
        row: 1,
        col: 1,
        globalIndex: metadata.globalIndex,
        walletAddress: metadata.walletAddress,
        tokenId: metadata.tokenId,
      };
    });
  };

  const onGetNFTBalances = function () {
    if (!isAuthenticated) {
      return;
    }
    getNFTBalances({
      params: {
        chain: "0x1",
        address: selectedWalletAddress,
      },
      // params: { chain: "0x1" },
      onSuccess: onGetNFTBalancesComplete,
    });
  };

  const onGetNFTBalancesComplete = function (nftBalances) {
    var index = 0;
    setNFTNotes("You don't have any NFT ampersand");

    /*
    + ------------------------------------------ +
    + get total metadatas
    + ------------------------------------------ +
    */
    var totalMetadatas = [];
    var totalCount = 0;
    for (index = 0; index < nftBalances.result.length; index++) {
      var nftMetadata = JSON.parse(nftBalances.result[index].metadata);
      if (nftMetadata == null) continue;
      if (
        nftMetadata.name == null ||
        nftMetadata.description == null ||
        nftMetadata.image == null
      ) {
        continue;
      }
      var _row = 1,
        _col = 1;
      switch (totalCount % 8) {
        case 0:
        case 5:
          _row = 2;
          _col = 2;
          break;
        case 3:
        case 4:
          _col = 2;
          break;
        default:
          break;
      }
      var _title = nftMetadata.name != null ? nftMetadata.name : "No title";
      var _description =
        nftMetadata.description != null
          ? nftMetadata.description
          : "No description";
      var _image = nftMetadata.image != null ? nftMetadata.image : "";
      var _walletAddress = nftBalances.result[index].owner_of
        ? nftBalances.result[index].owner_of
        : "";
      var _tokenId = nftBalances.result[index].token_id
        ? nftBalances.result[index].token_id
        : "";
      var metadata = {
        title: _title,
        desc: _description,
        image: _image,
        row: _row,
        col: _col,
        globalIndex: totalCount,
        walletAddress: _walletAddress,
        tokenId: _tokenId,
      };
      totalMetadatas.push(metadata);
      totalCount++;
    }

    /*
    + ------------------------------------------ +
    + get page count, index
    + ------------------------------------------ +
    */
    var pageCount = 0;
    var pageIndex = -1;
    if (totalCount > 0) {
      pageIndex = 0;
      pageCount = Math.floor(totalCount / 12);
      if (totalCount % 12 != 0) {
        pageCount++;
      }
    }

    /*
    + ------------------------------------------ +
    + get render metadatas
    + ------------------------------------------ +
    */
    var renderMetadatas = [];
    var renderCount = 0;
    if (pageCount > 0 && pageIndex >= 0) {
      var startIndex = pageIndex * 12;
      var endIndex = startIndex + 12;
      if (endIndex > totalCount) {
        endIndex = totalCount;
      }
      for (index = startIndex; index < endIndex; index++) {
        renderMetadatas.push(totalMetadatas[index]);
        renderCount++;
      }
    }

    /*
    + ------------------------------------------ +
    + update states
    + ------------------------------------------ +
    */
    setNFTDatas((previousState) => {
      return {
        ...previousState,
        renderCount: renderCount,
        totalCount: totalCount,
        renderMetadatas: renderMetadatas,
        totalMetadatas: totalMetadatas,
        pageCount: pageCount,
        pageIndex: pageIndex,
      };
    });

    if (pageCount > 0 && pageIndex >= 0) {
      setNFTNotes("Page Info : [" + (pageIndex + 1) + "/" + pageCount + "]");
    }
    // setSelectedWalletAddress("");
    setSelectedTokenID("");
  };

  const onPublish = (e) => {
    e.preventDefault();
    onGetNFTBalances();
  };

  const formClasses = useFormStyles();

  return (
    <Box>
      <Container fixed sx={{ padding: "4% !important" }}>
        <Grid
          container
          alignItems="top"
          direction="row"
          columnSpacing={{ xs: 1, sm: 2, lg: 8 }}
        >
          <Grid item xs={12} sm={7}>
            <Card sx={{ padding: "1rem" }}>
              <Box className={pageClasses.portfolioTitleGrp}>
                <Typography
                  className={pageClasses.portfolioTitle}
                  variant="h2"
                  component="h2"
                >
                  NFT TOKEN
                </Typography>
              </Box>

              <NFTTokenList
                metadatas={nftDatas.renderMetadatas}
                nftNotes={nftNotes}
                onPrevPage={onPrevPage}
                onNextPage={onNextPage}
                onNFTTokenSelected={onNFTTokenSelected}
              />

              <NFTTokenDetail
                metadata={selectedMetadata}
                onBackToPortfolio={onBackToPortfolio}
              />

              <Box className={pageClasses.walletGroup}>
                <span className={pageClasses.walletGroup_Label}>
                  Wallet Address
                </span>

                <FormControl
                  className={clsx(
                    formClasses.margin,
                    formClasses.textField,
                    formClasses.borderProp
                  )}
                  variant="outlined"
                >
                  <OutlinedInput
                    className={pageClasses.walletGroup_Input}
                    id="selected-wallet-address"
                    type={"text"}
                    startAdornment={
                      <InputAdornment position="start">
                        <img src={wallet_img} />
                      </InputAdornment>
                    }
                    value={selectedWalletAddress}
                    onChange={(e) => setSelectedWalletAddress(e.target.value)}
                  />
                </FormControl>
              </Box>
              <Box className={pageClasses.tokenGroup}>
                <span className={pageClasses.tokenGroup_Label}>Token Id</span>
                <FormControl
                  className={clsx(
                    formClasses.margin,
                    formClasses.textField,
                    formClasses.borderProp
                  )}
                  variant="outlined"
                >
                  <OutlinedInput
                    className={pageClasses.tokenGroup_Input}
                    id="selected-token-id"
                    type={"text"}
                    startAdornment={
                      <InputAdornment position="start">
                        <img src={token_img} />
                      </InputAdornment>
                    }
                    value={selectedTokenID}
                    onChange={(e) => setSelectedTokenID(e.target.value)}
                    readOnly={true}
                  />
                </FormControl>
              </Box>
              <Box className={pageClasses.publishGrp}>
                <Button
                  color="error"
                  variant="contained"
                  className={pageClasses.publishBtn}
                  onClick={onPublish}
                >
                  PUBLISH
                </Button>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Card sx={{ padding: "1rem" }}>
              <Box className={pageClasses.portfolioTitleGrp}>
                <Typography
                  className={pageClasses.portfolioTitle}
                  variant="h2"
                  component="h2"
                  sx={{ textAlign: "center" }}
                >
                  Share
                </Typography>
              </Box>
              <SocialMediaList />
              <Box className={pageClasses.qrCodeGroup}>
                <a
                  href={tokenLink}
                  target="_blank"
                  rel="noreferrer"
                  className={pageClasses.qrCodeGroup_Link}
                >
                  {/* <QRCode value={tokenLink} /> */}
                </a>
              </Box>
              <EmbedGroup />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LearnerExperience;
