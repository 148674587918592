import styled from "styled-components";
export const WalletSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  button.myWallet {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 98px;
    height: 37px;
    background: #eb1f24;
    border-radius: 8px;
  }
  button.logout {
    background: green;
    color: #fff;
  }
`;
