import React from "react";

import { Container, Box, Paper, Grid, Typography, Button } from "@mui/material";
import { useMoralis } from "react-moralis";
import { navigate } from "@reach/router";
import swal from "sweetalert";

const getStartedData = [
  { keyword: "Sign In", image: "signin" },
  { keyword: "Upload", image: "upload" },
  { keyword: "Get It", image: "getIt" },
];

const GetStartedNow = () => {
  const { isAuthenticated } = useMoralis();

  const handleNext = () => {
    if (!isAuthenticated) {
      swal({
        title: "Please",
        text: "connect your wallet first",
        icon: "info",
      });
    } else {
      navigate("/creator/mint");
    }
  };
  return (
    <Box>
      <Container fixed>
        <Paper
          sx={{
            p: "5% 10%",
            margin: "auto",
            maxWidth: 600,
            flexGrow: 1,
            backgroundColor: "#EB1F24",
            borderRadius: "10px",
          }}
        >
          <Grid container spacing={4}>
            {getStartedData.map((started, index) => (
              <Grid key={index} item xs={12} md={6} lg={4}>
                <Box
                  sx={{
                    border: "1px solid #FFFFFF3B",
                    borderRadius: "21px",
                    background: "rgba(255, 255, 255, 0.23)",
                    padding: "20% 28%",
                  }}
                >
                  <Box
                    sx={{
                      background: "#FFFFFF",
                      borderRadius: "8px",
                      padding: "8px",
                      textAlign: "center",
                      width: "53px",
                      height: "53px",
                      margin: "auto",
                      marginBottom: "30%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={`images/${started.image}.svg`}
                      alt={started.keyword}
                    />
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "20px",
                      lineHeight: "23px",
                      textAlign: "center",
                      color: "#FFFFFF",
                    }}
                  >
                    {started.keyword}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ textAlign: "center", paddingTop: "10%" }}>
            <Button
              sx={{
                width: "282px",
                height: "60px",
                fontSize: "22px",
                lineHeight: "26px",
                borderRadius: "10px",
                background: "#fff",
                color: "#eb1f24",
                "&:hover": {
                  background: "rgba(255, 255, 255, 0.23)",
                  color: "#fff",
                },
                "@media (max-width: 1199.9px)": {
                  width: "200px",
                  height: "40px",
                  fontSize: "16px",
                  lineHeight: "20px",
                  borderRadius: "8px",
                },
              }}
              onClick={handleNext}
            >
              GET STARTED NOW
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default GetStartedNow;
