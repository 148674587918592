import { React, memo } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

import imageMarkCopy from "../../assets/images/Mark_Copy.png";
import imageMarkFacebook from "../../assets/images/Mark_Facebook.png";
import imageMarkInstagram from "../../assets/images/Mark_Instagram.png";
import imageMarkLinkedIn from "../../assets/images/Mark_LinkedIn.png";
import imageMarkMail from "../../assets/images/Mark_Mail.png";
import imageMarkTelegram from "../../assets/images/Mark_Telegram.png";
import imageMarkTwitter from "../../assets/images/Mark_Twitter.png";
import imageMarkWhatapp from "../../assets/images/Mark_Whatapp.png";

import learnerExperienceStyle from "./style";

const useStyles = makeStyles(learnerExperienceStyle);

const SocialMediaList = (props) => {
  const pageClasses = useStyles();

  return (
    <Box className={pageClasses.socialNetworkGroup}>
      <Box className={pageClasses.socialNetworkRow}>
        <Box className={pageClasses.socialNetworkMark}>
          <img
            src={imageMarkLinkedIn}
            alt="..."
            className={pageClasses.socialNetworkMark_Img}
            // className={classes.imgRounded + " " + classes.imgFluid}
          />
          <span className={pageClasses.socialNetworkMark_Desc}>Linked In</span>
        </Box>
        <Box className={pageClasses.socialNetworkMark}>
          <img
            src={imageMarkTwitter}
            alt="..."
            className={pageClasses.socialNetworkMark_Img}
            // className={classes.imgRounded + " " + classes.imgFluid}
          />
          <span className={pageClasses.socialNetworkMark_Desc}>Twitter</span>
        </Box>
        <Box className={pageClasses.socialNetworkMark}>
          <img
            src={imageMarkWhatapp}
            alt="..."
            className={pageClasses.socialNetworkMark_Img}
            // className={classes.imgRounded + " " + classes.imgFluid}
          />
          <span className={pageClasses.socialNetworkMark_Desc}>Whatsapp</span>
        </Box>
        <Box className={pageClasses.socialNetworkMark}>
          <img
            src={imageMarkTelegram}
            alt="..."
            className={pageClasses.socialNetworkMark_Img}
            // className={classes.imgRounded + " " + classes.imgFluid}
          />
          <span className={pageClasses.socialNetworkMark_Desc}>Telegram</span>
        </Box>
      </Box>
      <Box className={pageClasses.socialNetworkRow}>
        <Box className={pageClasses.socialNetworkMark}>
          <img
            src={imageMarkFacebook}
            alt="..."
            className={pageClasses.socialNetworkMark_Img}
            // className={classes.imgRounded + " " + classes.imgFluid}
          />
          <span className={pageClasses.socialNetworkMark_Desc}>Facebook</span>
        </Box>
        <Box className={pageClasses.socialNetworkMark}>
          <img
            src={imageMarkInstagram}
            alt="..."
            className={pageClasses.socialNetworkMark_Img}
            // className={classes.imgRounded + " " + classes.imgFluid}
          />
          <span className={pageClasses.socialNetworkMark_Desc}>Instagram</span>
        </Box>
        <Box className={pageClasses.socialNetworkMark}>
          <img
            src={imageMarkMail}
            alt="..."
            className={pageClasses.socialNetworkMark_Img}
            // className={classes.imgRounded + " " + classes.imgFluid}
          />
          <span className={pageClasses.socialNetworkMark_Desc}>Email</span>
        </Box>
        <Box className={pageClasses.socialNetworkMark}>
          <img
            src={imageMarkCopy}
            alt="..."
            className={pageClasses.socialNetworkMark_Img}
            // className={classes.imgRounded + " " + classes.imgFluid}
          />
          <span className={pageClasses.socialNetworkMark_Desc}>Copy</span>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(SocialMediaList);
