const walletProfileStyle = {
  wallet_grid: {
    marginTop: "30px",
  },
  wallet_link: {
    backgroundColor: "white",
    "@media (min-width: 778px)": {
      width: "100%",
    },

    border: "1px solid gray",
    padding: "0 10px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  wallet_link_input: {
    // backgroundColor: "white",
    marginLeft: "10px",
    padding: "15px 0",
    width: "100%",
    border: "none",
    outline: "none",
    color: "#e52d27",
    "&::placeholder": {
      color: "#e52d27",
    },
  },
  walletPortfolio: {
    marginTop: "15px",
    "@media (max-width: 778px)": {
      marginTop: "0px",
      marginBottom: "20px",
    },
  },
};

export default walletProfileStyle;
