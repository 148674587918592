import { Box } from '@mui/system'
import React from 'react'

export default function FacebookLikeShareButton() {
  return (
    <Box textAlign="center" marginTop={2} marginBottom={1}>
      <div
        className="fb-like"
        data-href="https://www.facebook.com/LillupWorkplace"
        data-width=""
        data-layout="button_count"
        data-action="like"
        data-size="large"
        data-share={true}
      ></div>
    </Box>
  )
}
