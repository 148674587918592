import React, { useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import { useMoralis } from "react-moralis";
import { WalletSection } from "./style";
import { navigate, useLocation } from "@reach/router";
import { AppContext } from "../../utils/context";
import swal from "sweetalert";

const Wallet = () => {
  const { locationPath, setLocationPath } = useContext(AppContext);
  const location = useLocation();

  const { enableWeb3, isAuthenticated, logout, isWeb3Enabled } = useMoralis();

  const userLogout = async () => {
    await logout();
    navigate("/");
    swal({
      title: "Logout",
      icon: "success",
      dangerMode: false,
    });
  };

  console.log(isAuthenticated, isWeb3Enabled);

  if (!isAuthenticated) {
    return (
      <WalletSection>
        <Button
          onClick={() => {
            if (location.pathname === "/walletSignin") {
              swal({
                title: "Alert",
                text: "Please Login First",
                icon: "info",
              });
            } else {
              navigate("/walletSignin");
              setLocationPath(location.pathname);
            }
          }}
          className="myWallet login"
        >
          My Wallet
        </Button>
      </WalletSection>
    );
  }

  if (!isWeb3Enabled) {
    return (
      <WalletSection>
        <Button
          onClick={async () => {
            await enableWeb3();
            navigate(locationPath);
          }}
          className="myWallet login"
        >
          My Wallet
        </Button>
      </WalletSection>
    );
  }

  return (
    <>
      <WalletSection>
        <Button
          onClick={userLogout}
          className={
            isWeb3Enabled && isAuthenticated ? "myWallet logout" : "myWallet"
          }
        >
          My Wallet
        </Button>
      </WalletSection>
    </>
  );
};

export default Wallet;
