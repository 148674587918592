import React, { useState, useEffect } from "react";
import { Container, Box, Typography, Grid } from "@mui/material";
import linkedin1 from "../../assets/images/linkedin1.png";
import linkedin2 from "../../assets/images/linkedin2.png";
import { Link } from "@reach/router";
import LinkedinSectionStyle, { AnimationText } from "./style.js";
import { IconButton, makeStyles } from "@material-ui/core";
import CirclesImage from "../../assets/images/home-circles.svg";
import CirclesImageRight from "../../assets/images/home-circles-right.svg";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";

const useStyles = makeStyles(LinkedinSectionStyle);

export default function LinkedinSection() {
  const classes = useStyles();

  const [items, setItems] = useState([
    "Learners",
    "Instructors",
    "Teachers",
    "Creators",
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      setItems((items) => {
        const [first, ...rest] = items;
        return [...rest, first];
      });
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={classes.mainDiv} id="linkedin">
      <Box
        sx={{
          paddingY: "10%",
          backgroundColor: `#0A66C2;`,
          padding: "100px 0 57px 0",
        }}
      >
        <div className={classes.circlesLeft}>
          <img src={CirclesImage} />
        </div>
        <div className={classes.circlesRight}>
          <img src={CirclesImageRight} />
        </div>
        <Container style={{ zIndex: 2, position: "relative" }}>
          <Box
            sx={{
              textAlign: "center",
              marginBottom: "51px",
            }}
          >
            <div className={classes.linkSection}>
              <Link to="/#linkedin">
                <IconButton
                  aria-label="Example"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      "https://www.lillup.com/linkedin"
                    );
                  }}
                >
                  <BsThreeDotsVertical size={26} color="white" />
                </IconButton>
              </Link>
            </div>
            <AnimationText>
              <Typography
                variant="h2"
                sx={{
                  color: "white",
                  fontSize: "36px",
                  fontWeight: "700",
                  lineHeight: "42.19px",
                  "@media(min-width: 780px)": {
                    fontSize: "96px",
                    lineHeight: " 112.5px",
                  },
                }}
                className="top"
              >
                {items[0]}
              </Typography>
            </AnimationText>
            <Typography
              variant="h4"
              sx={{
                color: "white",
                fontSize: "26px",
                fontWeight: "700",
                lineHeight: "32.19px",
                marginBottom: "51px",
                "@media(min-width: 780px)": {
                  fontSize: "36px",
                  lineHeight: " 42.19px",
                  marginBottom: "51px",
                },
              }}
            >
              Everyone Forward
            </Typography>
            <Typography
              variant="p"
              sx={{
                color: "white",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "19.13px",
                "@media(min-width: 780px)": {
                  fontSize: "24px",
                  lineHeight: " 29.13px",
                },
              }}
            >
              Build your Experience
            </Typography>
          </Box>
          <Grid container spacing={4} justifyContent={"center"}>
            <Grid item xs={12} md={6}>
              <img src={linkedin1} alt="Linkedin" />
            </Grid>
            {/* <Grid item xs={12} md={6}>
            <a href="">
              <img src={linkedin2} alt="Linkedin" />
            </a>
          </Grid> */}
          </Grid>
        </Container>
      </Box>
    </div>
  );
}
