const videoStyle = {
  playerWrapper: {
    width: "65%",
    margin: "0 auto",
    boxShadow: "0px 9px 31px rgba(202, 202, 202, 0.25)",

    "@media (max-width: 576px)": {
      width: "100%",
      margin: "0 auto"
    },
    "@media (max-width: 767.98px)": {
      width: "80%",
      margin: "0 auto"
    }
  },
  videoResponsive: {
    overflow: "hidden",
    paddingBottom: "56.25%",
    position: "relative",
    height: "0"
  },
  iframe: {
    left: "-5px",
    top: "5px",
    height: "100%",
    width: "100%",
    position: "absolute"
  }
};

export default videoStyle;
