import React, { useState } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core';
import { BsTwitter } from 'react-icons/bs';
import { ImFacebook, ImInstagram } from 'react-icons/im';
import { SiLinkedin } from 'react-icons/si';

import socialProfileStyle from './style/socialProfileStyle.js';
import SaveButton from './SaveButton.js';
import { useNavigate } from '@reach/router';

const useStyles = makeStyles(socialProfileStyle);

export default function SocialProfile({ setFormData, formData }) {
	const classes = useStyles();
	let navigate = useNavigate();

	return (
		<div className={classes.social_grid}>
			<h3 className={classes.body_title}>Social Links</h3>
			<div className={classes.social_link}>
				<BsTwitter color="gray" size="1.4em" />{' '}
				<input
					value={formData.twitter}
					className={classes.social_link_input}
					placeholder="www.twitter.com"
					onChange={(e) => setFormData({ ...formData, twitter: e.target.value })}
				/>
			</div>
			<div className={classes.social_link}>
				<ImFacebook color="gray" size="1.4em" />{' '}
				<input
					value={formData.instagram}
					className={classes.social_link_input}
					placeholder="www.instagram.com"
					onChange={(e) => setFormData({ ...formData, instagram: e.target.value })}
				/>
			</div>
			<div className={classes.social_link}>
				<ImInstagram color="gray" size="1.4em" />{' '}
				<input
					value={formData.facebook}
					className={classes.social_link_input}
					placeholder="www.facebook.com"
					onChange={(e) => setFormData({ ...formData, facebook: e.target.value })}
				/>
			</div>
			<div className={classes.social_link_grid}>
				<div className={classes.social_link}>
					<SiLinkedin color="gray" size="1.4em" />{' '}
					<input
						value={formData.linkedin}
						className={classes.social_link_input}
						placeholder="www.linkedin.com"
						onChange={(e) => setFormData({ ...formData, linkedin: e.target.value })}
					/>
				</div>
				<div className={classes.wallet_button}>
					<SaveButton
						color="primary"
						onClick={() => {
							navigate('/creator/create');
						}}
						formData={formData}
					>
						Save
					</SaveButton>
				</div>
			</div>
		</div>
	);
}
