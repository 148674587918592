import styled from "styled-components";

export const StyledNav = styled.nav``;

export const StyledList = styled.ul`
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  @media (max-width: 899.9px) {
    flex-direction: column;
    margin-top: 6%;
    align-items: flex-start;
  }
`;

export const StyledListItem = styled.li`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 40px;
  &:last-child {
    margin-right: 0;
  }
  &:hover > .child-nav-item {
    display: block;
  }
  a {
    font-size: 14px;
    line-height: 16px;
    /* text-align: center; */
    text-transform: uppercase;
    text-decoration: none;
    color: #505050;
    transition: all 0.2s linear;
    &:hover,
    &.active {
      color: #eb1f24;
    }
  }
  @media (max-width: 899.9px) {
    margin: 0;
    a {
      font-size: 18px;
      display: block;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
`;

export const StyledListItemParent = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #505050;
  img {
    margin-left: 9px;
  }
  @media (max-width: 899.9px) {
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const StyledDropDown = styled.div`
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 5;
  .MuiPaper-root {
    overflow: unset;
    -webkit-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
    margin-top: 1.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    min-width: 230px;
    a {
      display: block;
      padding: 8px 16px;
      font-size: 14px;
      text-transform: capitalize;
      color: #505050;
      text-decoration: none;
    }
  }
  @media (max-width: 899.9px) {
    position: unset;
    .MuiPaper-root {
      background-color: transparent;
      min-width: auto;
      border: none;
      box-shadow: unset;
      margin: 0;
    }
  }
`;
