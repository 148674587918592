import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core';
import portfolioFormStyle from './style/portfolioFormStyle';

const useStyles = makeStyles(portfolioFormStyle);

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	backgroundColor: '#e52d27',
	padding: '20px',
	margin: '10px 0',
	color: 'white',
	borderWidth: '2',
	borderRadius: '2',
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	height: '170px'
};

export default function PortFolioForm() {
	const classes = useStyles();

	return (
		<div className={classes.formContainer} style={{ height: '100vh' }}>
			<h2 className="has-text-centered">Port Folio</h2>
		</div>
	);
}
