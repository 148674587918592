import React, { useState, useLayoutEffect, useContext } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Link } from "@reach/router";
import Navigation from "../../components/Navigation";
import Button from "@mui/material/Button";
import { TopSectionWrap, HeaderWrap, LoginSection } from "./style";
import Wallet from "../../components/Wallet/Wallet";
import PropTypes from "prop-types";
import { AppContext } from "../../utils/context";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import logo from "../../assets/images/logo.svg";
import close from "../../assets/images/close.svg";
import menuBar from "../../assets/images/menu_bar.svg";

const Header = ({ showBanner }) => {
  const { isOpen, setIsOpen } = useContext(AppContext);
  const [open, setOpen] = useState(true);

  const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      const updateSize = () => {
        setSize([window.innerWidth, window.innerHeight]);
      };
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  };
  const [size] = useWindowSize();
  const handleMenuToggle = () => {
    isOpen
      ? document.body.classList.remove("main-nav-open")
      : document.body.classList.add("main-nav-open");
    setIsOpen(!isOpen);
  };

  const renderMobileBar = () => {
    return (
      <HeaderWrap className={isOpen ? "menu-open" : ""}>
        <Container fixed>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Link to="/">
                <img className="logo" src={logo} alt="Lillup" />
              </Link>
            </Grid>
            <Grid item xs={6} align="end">
              <LoginSection>
                <Wallet />
                <Button onClick={handleMenuToggle}>
                  {isOpen ? (
                    <img src={close} alt="close" />
                  ) : (
                    <img src={menuBar} alt="menu bar" />
                  )}
                </Button>
              </LoginSection>
            </Grid>
          </Grid>
          {isOpen ? <Navigation /> : null}
        </Container>
      </HeaderWrap>
    );
  };
  const renderDesktopBar = () => {
    return (
      <HeaderWrap>
        <Container fixed>
          <Grid container alignItems="center">
            <Grid item xs={3}>
              <Link to="/">
                <img className="logo" src={logo} alt="Lillup" />
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Navigation />
            </Grid>
            <Grid item xs={3} align="end">
              <Wallet />
            </Grid>
          </Grid>
        </Container>
      </HeaderWrap>
    );
  };

  return (
    <>
      <Stack sx={{ width: "100%" }} spacing={2}>
        <Collapse in={open}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{
              backgroundColor: "#8247e5",
              color: "#fff",
              borderRadius: 0,
              padding: "0 16px",
              // fontSize: "2rem",
              ".MuiAlert-icon": {
                color: "white",
              },
            }}
            severity="info"
          >
            &chain protocol on
            <a
              style={{ color: "#fff", marginLeft: "5px" }}
              target="_blank"
              href="https://mumbai.polygonscan.com"
            >
              Mumbai Polygon
            </a>
          </Alert>
        </Collapse>
      </Stack>
      <TopSectionWrap
        className={`${showBanner ? "paddingNone" : ""}  ${
          isOpen ? "menu-open" : ""
        }`}
      >
        {size >= 899.9 ? renderDesktopBar() : renderMobileBar()}
      </TopSectionWrap>
    </>
  );
};
Header.defaultProps = {
  showBanner: true,
};

Header.propTypes = {
  showBanner: PropTypes.bool,
};
export default Header;
