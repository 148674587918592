import styled from "styled-components";
import BG1 from "../../assets/images/bg-1.svg";
import BG2 from "../../assets/images/bg-2.svg";

export const TopSectionWrap = styled.div`
  padding-top: 28px;
  /* padding-bottom: 165px; */
  background-image: url(${BG1}), url(${BG2});
  background-repeat: no-repeat, no-repeat;
  background-size: cover;
  @media (max-width: 1199.9px) {
    /* padding-bottom: 120px; */
  }
  @media (max-width: 899.9px) {
    padding-top: 18px;
  }
  &.menu-open {
    background: #f9d0d1;
  }
  /* &.paddingNone {
    padding-bottom: 0;
  } */
`;

export const HeaderWrap = styled.header`
  padding: 0 3.3%;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(255, 85, 0, 0.16);
  &.menu-open {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: #f9d0d1;
    left: 0px;
    z-index: 1;
    padding: 0;
  }
  img.logo {
    width: 172px;
  }
  @media (max-width: 1199.9px) {
    img.logo {
      width: 130px;
    }
  }
`;

export const LoginSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  /* font-style: normal;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
  } */
  a.login {
    font-weight: normal;
    color: #eb1f24;
  }
  a.myWallet {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 98px;
    height: 37px;
    background: #eb1f24;
    border-radius: 8px;
  }
`;
