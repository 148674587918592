import React, { useEffect } from "react";
import GetStartedNow from "../components/CreatorGetStartedNow";
import LatestNews from "../components/LatestNews";
import AboutCreator from "../components/AboutCreator/AboutCreator";

function Creator() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AboutCreator />
      <GetStartedNow />
      <LatestNews />
    </>
  );
}

export default Creator;
