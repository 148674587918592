import React, { useContext } from "react";
import { Link } from "@reach/router";
import Paper from "@mui/material/Paper";
import {
  StyledNav,
  StyledList,
  StyledListItem,
  StyledListItemParent,
  StyledDropDown,
} from "./style";
import PropTypes from "prop-types";
import { AppContext } from "../../utils/context";
import { useLocation } from "@reach/router";
import vector from "../../assets/images/Vector.svg";
const Navigation = ({ showNavbar }) => {
  const { setIsOpen } = useContext(AppContext);
  const location = useLocation();
  const handleClick = () => {
    setIsOpen(false);
  };
  // console.log(location.pathname);
  return (
    <StyledNav>
      <StyledList>
        <StyledListItem>
          <Link
            className={
              location?.pathname === "/creator" ? "active" : "nonActive"
            }
            to="creator"
            onClick={handleClick}
          >
            creator
          </Link>
        </StyledListItem>
        {/* <StyledListItem>
          <Link
            className={
              location?.pathname === "/learner" ? "active" : "nonActive"
            }
            to="learner"
            onClick={handleClick}
          >
            learner
          </Link>
        </StyledListItem> */}
        <StyledListItem>
          <Link
            className={
              location?.pathname === "/allTogether" ? "active" : "nonActive"
            }
            to="allTogether"
            onClick={handleClick}
          >
            All Together
          </Link>
        </StyledListItem>
        <StyledListItem
          className={location?.pathname === "/explore" ? "active" : "nonActive"}
        >
          <StyledListItemParent>
            {/* <Link
              className={
                location?.pathname === "/resources" ? "active" : "nonActive"
              }
              to="resources"
              onClick={handleClick}
            > */}
            resources
            {/* </Link> */}
            <img src={vector} alt="Dropdown" />
          </StyledListItemParent>
          <StyledDropDown className="child-nav-item">
            <Paper>
              <a target="_blank" href="https://mumbai.polygonscan.com/">
                explore
              </a>
              <a target="_blank" href="https://lillup.gitbook.io/">
                Documentation
              </a>
            </Paper>
          </StyledDropDown>
        </StyledListItem>
        <StyledListItem>
          <Link
            className={
              location?.pathname === "/stakeholder" ? "active" : "nonActive"
            }
            to="stakeholder"
            onClick={handleClick}
          >
            stakeholder
          </Link>
        </StyledListItem>
      </StyledList>
    </StyledNav>
  );
};
Navigation.defaultProps = {
  showNavbar: true,
};

Navigation.propTypes = {
  showNavbar: PropTypes.bool,
};
export default Navigation;
