import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import styles from './style/buttonStyle.js';

const useStyles = makeStyles(styles);

export default function SaveButton(props) {
	const { children, ...rest } = props;
	const classes = useStyles();

	const handleSave = () => {
		console.log([ props.formData ]);
	};

	return (
		<Button {...rest} className={classes.button} onClick={() => handleSave()}>
			{children}
		</Button>
	);
}
