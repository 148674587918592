import { React, memo } from "react";
import { Button, Box, ImageList } from "@mui/material";
import { Remove, Add } from "@mui/icons-material";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import NFTTokenPanel from "./NFTTokenPanel";
import learnerExperienceStyle from "./style";

const useStyles = makeStyles(learnerExperienceStyle);

const NFTTokenList = (props) => {
  const pageClasses = useStyles();

  return (
    <Box id="nft-token-list-wrapper" className={pageClasses.tokenCodeGrp}>
      <Box className={pageClasses.tokenCodeGrp}>
        <Box className={pageClasses.buttonGroup}>
          <Button
            color="info"
            size="sm"
            round
            className={pageClasses.firstButton}
            onClick={props.onPrevPage}
          >
            <Remove sx={{ color: "#fff" }} />
          </Button>
          <Button
            color="info"
            size="sm"
            round
            className={pageClasses.lastButton}
            onClick={props.onNextPage}
          >
            <Add sx={{ color: "#fff" }} />
          </Button>
          <span>&nbsp;&nbsp;&nbsp;{props.nftNotes}</span>
        </Box>
        <ImageList
          sx={{
            transform: "translateZ(0)",
            overflow: "unset",
          }}
          variant="quilted"
          cols={4}
          rowHeight={120}
          gap={0}
        >
          {props.metadatas.map((item, index) => (
            <NFTTokenPanel
              key={index}
              index={index}
              img={item.image}
              title={item.title}
              description={item.desc}
              cols={item.col || 1}
              rows={item.row || 1}
              globalIndex={item.globalIndex}
              onSelected={props.onNFTTokenSelected}
            />
          ))}
        </ImageList>
      </Box>
    </Box>
  );
};
export default memo(NFTTokenList);

NFTTokenList.propTypes = {
  metadatas: PropTypes.array.isRequired,
  nftNotes: PropTypes.string.isRequired,
  onPrevPage: PropTypes.func.isRequired,
  onNextPage: PropTypes.func.isRequired,
  onNFTTokenSelected: PropTypes.func.isRequired,
};

NFTTokenList.defaultProps = {
  metadatas: [],
  nftNotes: "",
  onPrevPage: null,
  onNextPage: null,
  onNFTTokenSelected: null,
};
