import React from "react";
import { Box } from "@mui/material";
import undefinedPage from "../assets/images/404.svg";

function Undefined() {
  return (
    <Box className="creator" sx={{ py: "7%", textAlign: "center" }}>
      <img src={undefinedPage} alt="undefined" />
    </Box>
  );
}

export default Undefined;
