import React from "react";
import FacebookIconWrapper from "./FacebookIconWrapper";

export default function AboutImage() {
  return (
    <svg
      viewBox="0 0 548 340"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M467.253 334.194H347.468H329.083H313.838H310.83H51.6797"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M176.956 246.047V248.907C176.956 250.368 176.425 251.705 175.55 252.744H144.412V246.04H176.956V246.047Z"
        fill="white"
      />
      <path
        d="M176.956 246.047V248.907C176.956 250.368 176.425 251.705 175.55 252.744H144.412V246.04H176.956V246.047Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M344.35 334.194L261.712 332.006L179.074 334.194C165.924 311.855 177.074 280.506 201.937 255.064C214.548 242.164 230.324 236.757 242.803 234.569C251.32 233.077 258.297 233.077 261.704 233.209C264.845 233.084 271.026 233.077 278.644 234.249C291.404 236.21 308.196 241.476 321.479 255.064C346.35 280.506 357.501 311.855 344.35 334.194Z"
        fill="#EB1F24"
      />
      <path
        d="M344.35 334.194L261.712 332.006L179.074 334.194C165.924 311.855 177.074 280.506 201.937 255.064C214.548 242.164 230.324 236.757 242.803 234.569C251.32 233.077 258.297 233.077 261.704 233.209C264.845 233.084 271.026 233.077 278.644 234.249C291.404 236.21 308.196 241.476 321.479 255.064C346.35 280.506 357.501 311.855 344.35 334.194Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M176.652 294.477C176.652 294.477 184.833 286.03 195.21 289.156C205.274 292.195 211.916 304.932 210.892 311.98C210.361 315.652 204.969 317.668 199.257 313.277C193.538 308.893 176.652 294.477 176.652 294.477Z"
        fill="#1F1F1F"
      />
      <path
        d="M176.652 294.477C176.652 294.477 184.833 286.03 195.21 289.156C205.274 292.195 211.916 304.932 210.892 311.98C210.361 315.652 204.969 317.668 199.257 313.277C193.538 308.893 176.652 294.477 176.652 294.477Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M346.756 294.477C346.756 294.477 338.575 286.03 328.198 289.156C318.134 292.195 311.492 304.932 312.516 311.98C313.047 315.652 318.439 317.668 324.151 313.277C329.87 308.893 346.756 294.477 346.756 294.477Z"
        fill="#1F1F1F"
      />
      <path
        d="M346.756 294.477C346.756 294.477 338.575 286.03 328.198 289.156C318.134 292.195 311.492 304.932 312.516 311.98C313.047 315.652 318.439 317.668 324.151 313.277C329.87 308.893 346.756 294.477 346.756 294.477Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M263.97 247.712C263.181 247.766 262.344 247.798 261.469 247.798C260.774 247.798 260.11 247.782 259.469 247.751C242.029 246.876 242.818 234.577 242.818 234.577C251.335 233.084 258.313 233.084 261.719 233.217C264.86 233.092 271.041 233.084 278.66 234.257C278.652 234.249 280.761 246.602 263.97 247.712Z"
        fill="#1F1F1F"
      />
      <path
        d="M271.566 225.56V238.515C271.566 238.515 268.519 242.703 260.775 242.703C253.032 242.703 249.969 238.515 249.969 238.515V225.56H271.566Z"
        fill="white"
      />
      <path
        d="M271.566 225.56V238.515C271.566 238.515 268.519 242.703 260.775 242.703C253.032 242.703 249.969 238.515 249.969 238.515V225.56H271.566Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M281.979 205.338C281.979 220.7 272.251 232.365 260.257 232.365C248.263 232.365 238.535 220.707 238.535 205.338C238.535 189.968 248.263 175.138 260.257 175.138C272.259 175.13 281.979 189.968 281.979 205.338Z"
        fill="white"
      />
      <path
        d="M281.979 205.338C281.979 220.7 272.251 232.365 260.257 232.365C248.263 232.365 238.535 220.707 238.535 205.338C238.535 189.968 248.263 175.138 260.257 175.138C272.259 175.13 281.979 189.968 281.979 205.338Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M286.255 199.384C286.114 199.782 285.966 200.149 285.825 200.478C285.38 201.525 284.95 202.29 284.606 202.806C284.176 203.463 283.887 203.744 283.887 203.744C283.715 203.83 283.543 203.908 283.371 203.986C283.098 204.119 282.824 204.236 282.551 204.353C282.356 204.439 282.16 204.517 281.965 204.588C281.793 204.658 281.613 204.728 281.441 204.791C261.118 212.347 246.663 187.96 246.663 187.96C246.561 198.993 241.271 205.416 239.279 207.448C238.982 207.752 238.755 207.955 238.638 208.065C238.575 208.127 238.536 208.159 238.536 208.159C238.146 207.698 237.778 207.229 237.419 206.768C237.341 206.666 237.263 206.565 237.192 206.463C236.762 205.893 236.364 205.314 235.989 204.744C235.372 203.814 234.825 202.884 234.34 201.954C233.903 201.126 233.52 200.298 233.192 199.47C232.121 196.844 231.543 194.25 231.363 191.726C230.308 176.7 243.701 164.159 256.195 162.941C273.089 161.292 276.394 169.801 276.394 169.801C276.394 169.801 276.402 169.801 276.425 169.793C278.644 169.192 287.833 171.551 288.091 185.452C288.216 192.07 287.27 196.532 286.255 199.384Z"
        fill="#EB1F24"
      />
      <path
        d="M236.627 210.034L233.799 209.338L234.666 205.791C234.338 203.314 232.533 187.007 241.136 176.982C245.48 171.918 251.724 169.355 259.701 169.355C268.687 169.355 275.516 172.192 279.994 177.779C288.839 188.804 285.237 206.494 284.791 208.471L281.947 207.838C282.353 205.994 285.713 189.562 277.72 179.599C273.821 174.731 267.749 172.27 259.694 172.27C252.614 172.27 247.113 174.489 243.347 178.873C235.088 188.476 237.557 205.471 237.581 205.642L237.628 205.924L236.627 210.034Z"
        fill="white"
      />
      <path
        d="M236.627 210.034L233.799 209.338L234.666 205.791C234.338 203.314 232.533 187.007 241.136 176.982C245.48 171.918 251.724 169.355 259.701 169.355C268.687 169.355 275.516 172.192 279.994 177.779C288.839 188.804 285.237 206.494 284.791 208.471L281.947 207.838C282.353 205.994 285.713 189.562 277.72 179.599C273.821 174.731 267.749 172.27 259.694 172.27C252.614 172.27 247.113 174.489 243.347 178.873C235.088 188.476 237.557 205.471 237.581 205.642L237.628 205.924L236.627 210.034Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M266.064 201.447C265.689 202.087 270.354 204.017 271.018 203.353C271.69 202.681 266.588 200.54 266.064 201.447Z"
        fill="black"
      />
      <path
        d="M267.385 210.479C266.908 210.479 266.525 210.096 266.525 209.62V207.526C266.525 207.049 266.908 206.666 267.385 206.666C267.862 206.666 268.244 207.049 268.244 207.526V209.62C268.237 210.096 267.854 210.479 267.385 210.479Z"
        fill="black"
      />
      <path
        d="M254.256 210.479C253.779 210.479 253.396 210.096 253.396 209.62V207.526C253.396 207.049 253.779 206.666 254.256 206.666C254.733 206.666 255.115 207.049 255.115 207.526V209.62C255.115 210.096 254.733 210.479 254.256 210.479Z"
        fill="black"
      />
      <path
        d="M266.197 210.479C266.197 210.479 267.205 209.682 268.557 210.479"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M258.547 222.387C258.547 222.387 264.962 221.817 268.267 217.051"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M255.577 201.447C255.952 202.087 251.288 204.017 250.623 203.353C249.951 202.681 255.054 200.54 255.577 201.447Z"
        fill="black"
      />
      <path
        d="M255.436 210.479C255.436 210.479 254.428 209.682 253.076 210.479"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M258.797 211.464C258.797 211.464 258.297 214.003 260.774 213.753C263.243 213.495 263.314 210.831 261.532 210.768"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M193.436 289.202C208.696 294.117 207.024 327.224 198.14 334.194H165.369C165.369 334.194 163.955 314.714 169.8 303.541C175.636 292.359 185.943 286.788 193.436 289.202Z"
        fill="white"
      />
      <path
        d="M193.436 289.202C208.696 294.117 207.024 327.224 198.14 334.194H165.369C165.369 334.194 163.955 314.714 169.8 303.541C175.636 292.359 185.943 286.788 193.436 289.202Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M175.963 334.194H194.356C195.779 337.827 202.725 338.054 209.593 334.194C209.632 334.171 209.671 334.155 209.71 334.131C211.812 332.944 213.094 330.693 213.094 328.279V327.537C213.094 325.122 211.812 322.88 209.71 321.692C209.695 321.684 209.679 321.676 209.664 321.669C208.14 320.809 203.373 321.958 200.803 321.286C198.271 320.629 194.028 317.59 192.755 316.293C192.739 316.277 192.723 316.254 192.7 316.238C191.504 314.972 187.496 311.417 181.761 314.542C177.979 316.597 176.518 327.209 175.963 334.194Z"
        fill="white"
      />
      <path
        d="M175.963 334.194H194.356C195.779 337.827 202.725 338.054 209.593 334.194C209.632 334.171 209.671 334.155 209.71 334.131C211.812 332.944 213.094 330.693 213.094 328.279V327.537C213.094 325.122 211.812 322.88 209.71 321.692C209.695 321.684 209.679 321.676 209.664 321.669C208.14 320.809 203.373 321.958 200.803 321.286C198.271 320.629 194.028 317.59 192.755 316.293C192.739 316.277 192.723 316.254 192.7 316.238C191.504 314.972 187.496 311.417 181.761 314.542C177.979 316.597 176.518 327.209 175.963 334.194Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M209.913 334.007C209.803 334.069 209.702 334.132 209.592 334.186C202.716 338.046 195.778 337.82 194.356 334.186C194.012 333.319 193.988 332.248 194.363 330.99C196.598 323.466 204.326 323.849 204.326 323.849"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M329.995 289.202C314.735 294.117 316.407 327.224 325.291 334.194H358.062C358.062 334.194 359.476 314.714 353.631 303.541C347.795 292.359 337.496 286.788 329.995 289.202Z"
        fill="white"
      />
      <path
        d="M329.995 289.202C314.735 294.117 316.407 327.224 325.291 334.194H358.062C358.062 334.194 359.476 314.714 353.631 303.541C347.795 292.359 337.496 286.788 329.995 289.202Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M347.467 334.194H329.073C327.651 337.827 320.705 338.054 313.836 334.194C313.797 334.171 313.758 334.155 313.719 334.131C311.617 332.944 310.336 330.693 310.336 328.279V327.537C310.336 325.122 311.617 322.88 313.719 321.692C313.735 321.684 313.751 321.676 313.766 321.669C315.29 320.809 320.056 321.958 322.627 321.286C325.159 320.629 329.401 317.59 330.675 316.293C330.691 316.277 330.706 316.254 330.73 316.238C331.925 314.972 335.934 311.417 341.669 314.542C345.451 316.597 346.912 327.209 347.467 334.194Z"
        fill="white"
      />
      <path
        d="M347.467 334.194H329.073C327.651 337.827 320.705 338.054 313.836 334.194C313.797 334.171 313.758 334.155 313.719 334.131C311.617 332.944 310.336 330.693 310.336 328.279V327.537C310.336 325.122 311.617 322.88 313.719 321.692C313.735 321.684 313.751 321.676 313.766 321.669C315.29 320.809 320.056 321.958 322.627 321.286C325.159 320.629 329.401 317.59 330.675 316.293C330.691 316.277 330.706 316.254 330.73 316.238C331.925 314.972 335.934 311.417 341.669 314.542C345.451 316.597 346.912 327.209 347.467 334.194Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M319.104 323.849C319.104 323.849 326.84 323.466 329.067 330.99C329.442 332.248 329.418 333.319 329.075 334.186C327.653 337.82 320.706 338.046 313.838 334.186C313.729 334.124 313.627 334.069 313.518 334.007"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M354.344 334.936C354.344 334.936 358.22 337.093 359.767 331.951C361.549 326.021 358.579 314.355 355.399 313.722C352.227 313.089 350.445 317.66 350.445 317.66L354.344 334.936Z"
        fill="white"
      />
      <path
        d="M354.344 334.936C354.344 334.936 358.22 337.093 359.767 331.951C361.549 326.021 358.579 314.355 355.399 313.722C352.227 313.089 350.445 317.66 350.445 317.66L354.344 334.936Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M340.35 316.707C340.35 316.707 342.444 312.417 348.07 312.8C353.688 313.183 355.852 333.116 355.118 335.593C354.383 338.07 346.546 339.671 345.499 334.999C344.452 330.326 340.35 316.707 340.35 316.707Z"
        fill="white"
      />
      <path
        d="M340.35 316.707C340.35 316.707 342.444 312.417 348.07 312.8C353.688 313.183 355.852 333.116 355.118 335.593C354.383 338.07 346.546 339.671 345.499 334.999C344.452 330.326 340.35 316.707 340.35 316.707Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M346.413 334.194C346.335 334.968 346.178 335.616 345.92 336.093C343.912 339.89 335.669 339.789 333.762 334.194C333.731 334.1 333.708 334.006 333.676 333.913C332.481 329.818 331.285 321.161 330.676 316.285C330.691 316.269 330.707 316.246 330.73 316.23C331.926 314.964 335.934 311.409 341.67 314.535C343.561 316.769 346.983 328.685 346.413 334.194Z"
        fill="white"
      />
      <path
        d="M330.676 316.285C330.691 316.269 330.707 316.246 330.73 316.23C331.926 314.964 335.934 311.409 341.67 314.535C343.561 316.777 346.983 328.693 346.413 334.194C346.335 334.968 346.178 335.616 345.92 336.093C343.912 339.89 335.669 339.789 333.762 334.194C333.731 334.1 333.708 334.006 333.676 333.913C332.887 331.209 332.098 326.505 331.473 322.223"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M169.088 334.936C169.088 334.936 165.212 337.093 163.665 331.951C161.883 326.021 164.853 314.355 168.033 313.722C171.205 313.089 172.987 317.66 172.987 317.66L169.088 334.936Z"
        fill="white"
      />
      <path
        d="M169.088 334.936C169.088 334.936 165.212 337.093 163.665 331.951C161.883 326.021 164.853 314.355 168.033 313.722C171.205 313.089 172.987 317.66 172.987 317.66L169.088 334.936Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M183.082 316.707C183.082 316.707 180.988 312.417 175.362 312.8C169.744 313.183 167.58 333.116 168.314 335.593C169.049 338.07 176.886 339.671 177.933 334.999C178.988 330.326 183.082 316.707 183.082 316.707Z"
        fill="white"
      />
      <path
        d="M183.082 316.707C183.082 316.707 180.988 312.417 175.362 312.8C169.744 313.183 167.58 333.116 168.314 335.593C169.049 338.07 176.886 339.671 177.933 334.999C178.988 330.326 183.082 316.707 183.082 316.707Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177.018 334.194C177.097 334.968 177.253 335.616 177.511 336.093C179.519 339.89 187.762 339.789 189.669 334.194C189.7 334.1 189.723 334.006 189.755 333.913C190.95 329.818 192.146 321.161 192.755 316.285C192.74 316.269 192.724 316.246 192.701 316.23C191.505 314.964 187.497 311.409 181.761 314.535C179.87 316.769 176.448 328.685 177.018 334.194Z"
        fill="white"
      />
      <path
        d="M192.755 316.285C192.74 316.269 192.724 316.246 192.701 316.23C191.505 314.964 187.497 311.409 181.761 314.535C179.87 316.777 176.448 328.693 177.018 334.194C177.097 334.968 177.253 335.616 177.511 336.093C179.519 339.89 187.762 339.789 189.669 334.194C189.7 334.1 189.723 334.006 189.755 333.913C190.489 331.389 191.224 327.13 191.825 323.091"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M276.394 169.801C264.962 160.909 237.263 161.034 231.363 191.726C230.308 176.7 243.701 164.159 256.195 162.941C273.089 161.284 276.394 169.801 276.394 169.801Z"
        fill="white"
      />
      <path
        d="M286.255 199.384C286.114 199.782 285.966 200.149 285.825 200.478C285.38 201.525 284.95 202.29 284.606 202.806C284.176 203.463 283.887 203.744 283.887 203.744C283.715 203.83 283.543 203.908 283.371 203.986C283.098 204.119 282.824 204.236 282.551 204.353C282.356 204.439 282.16 204.517 281.965 204.588C281.793 204.658 281.613 204.728 281.441 204.791C261.118 212.347 246.663 187.96 246.663 187.96C246.561 198.993 241.271 205.416 239.279 207.448C238.982 207.752 238.755 207.955 238.638 208.065C238.575 208.127 238.536 208.159 238.536 208.159C238.146 207.698 237.778 207.229 237.419 206.768C237.341 206.666 237.263 206.565 237.192 206.463C236.762 205.893 236.364 205.314 235.989 204.744C235.372 203.814 234.825 202.884 234.34 201.954C233.903 201.126 233.52 200.298 233.192 199.47C232.121 196.844 231.543 194.25 231.363 191.726C230.308 176.7 243.701 164.159 256.195 162.941C273.089 161.292 276.394 169.801 276.394 169.801C276.394 169.801 276.402 169.801 276.425 169.793C278.644 169.192 287.833 171.551 288.091 185.452C288.216 192.07 287.27 196.532 286.255 199.384Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M283.699 216.246L282.309 216.121L283.801 199.165L288.169 199.548C290.091 199.72 291.513 201.415 291.341 203.338L290.724 210.362C290.404 213.925 287.262 216.566 283.699 216.246Z"
        fill="white"
      />
      <path
        d="M283.7 216.246L282.582 216.144C281.676 216.066 280.988 215.308 280.996 214.394L281.035 208.495C281.059 205.182 282.02 201.947 283.809 199.165L285.2 199.29C285.192 199.29 283.661 206.557 283.7 216.246Z"
        fill="black"
      />
      <path
        d="M236.676 216.246L238.066 216.121L236.527 199.165L232.159 199.564C230.237 199.735 228.823 201.439 228.995 203.361L229.628 210.386C229.964 213.941 233.112 216.566 236.676 216.246Z"
        fill="white"
      />
      <path
        d="M283.699 216.246L282.309 216.121L283.801 199.165L288.169 199.548C290.091 199.72 291.513 201.415 291.341 203.338L290.724 210.362C290.404 213.925 287.262 216.566 283.699 216.246Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M236.676 216.246L238.066 216.121L236.527 199.165L232.159 199.564C230.237 199.735 228.823 201.439 228.995 203.361L229.628 210.386C229.964 213.941 233.112 216.566 236.676 216.246Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M236.676 216.246L237.793 216.144C238.7 216.066 239.387 215.3 239.38 214.394L239.325 208.495C239.294 205.182 238.325 201.947 236.528 199.165L235.137 199.29C235.137 199.29 236.692 206.549 236.676 216.246Z"
        fill="black"
      />
      <path
        d="M283.7 216.246L282.582 216.144C281.676 216.066 280.988 215.308 280.996 214.394L281.035 208.495C281.059 205.182 282.02 201.947 283.809 199.165L285.2 199.29C285.192 199.29 283.661 206.557 283.7 216.246Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M236.676 216.246L237.793 216.144C238.7 216.066 239.387 215.3 239.38 214.394L239.325 208.495C239.294 205.182 238.325 201.947 236.528 199.165L235.137 199.29C235.137 199.29 236.692 206.549 236.676 216.246Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M525.52 69.0428V250.821C525.52 255.267 521.918 258.87 517.472 258.87H342.109C337.663 258.87 334.061 255.267 334.061 250.821V69.0428C334.061 64.5968 337.663 60.9946 342.109 60.9946H517.472C521.918 61.0024 525.52 64.6046 525.52 69.0428Z"
        fill="white"
      />
      <path
        d="M399.618 258.862H342.109C337.663 258.862 334.061 255.26 334.061 250.814V141.765"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M334.068 85.186V82.7871V69.0428C334.068 64.5968 337.67 60.9946 342.116 60.9946H517.48C521.926 60.9946 525.528 64.5968 525.528 69.0428V235.905"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M525.52 69.0428V77.005H334.068V69.0428C334.068 64.5968 337.67 60.9946 342.116 60.9946H517.48C521.918 61.0024 525.52 64.6046 525.52 69.0428Z"
        fill="#EB1F24"
      />
      <path
        d="M525.52 69.0428V77.005H334.068V69.0428C334.068 64.5968 337.67 60.9946 342.116 60.9946H517.48C521.918 61.0024 525.52 64.6046 525.52 69.0428Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M501.477 68.8789C501.477 70.4182 500.227 71.6683 498.688 71.6683C497.149 71.6683 495.898 70.4182 495.898 68.8789C495.898 67.3396 497.149 66.0894 498.688 66.0894C500.227 66.0894 501.477 67.3396 501.477 68.8789Z"
        fill="white"
      />
      <path
        d="M518.909 68.8789C518.909 70.4182 517.659 71.6683 516.12 71.6683C514.58 71.6683 513.33 70.4182 513.33 68.8789C513.33 67.3396 514.58 66.0894 516.12 66.0894C517.667 66.0894 518.909 67.3396 518.909 68.8789Z"
        fill="black"
      />
      <path
        d="M484.046 68.8789C484.046 70.4182 482.796 71.6683 481.256 71.6683C479.717 71.6683 478.467 70.4182 478.467 68.8789C478.467 67.3396 479.717 66.0894 481.256 66.0894C482.796 66.0894 484.046 67.3396 484.046 68.8789Z"
        fill="white"
      />
      <path
        d="M308.151 330.045H214.785L207.213 273.177C207.018 271.708 208.159 270.403 209.643 270.403H313.292C314.777 270.403 315.918 271.708 315.722 273.177L308.151 330.045Z"
        fill="#1F1F1F"
      />
      <path
        d="M308.151 330.045H214.785L207.213 273.177C207.018 271.708 208.159 270.403 209.643 270.403H313.292C314.777 270.403 315.918 271.708 315.722 273.177L308.151 330.045Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M308.151 328.139H214.785V334.194H308.151V328.139Z" fill="white" />
      <path
        d="M308.151 328.139H214.785V334.194H308.151V328.139Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M259.472 311.011C265.048 311.011 269.568 306.491 269.568 300.915C269.568 295.34 265.048 290.82 259.472 290.82C253.897 290.82 249.377 295.34 249.377 300.915C249.377 306.491 253.897 311.011 259.472 311.011Z"
        fill="white"
      />
      <path
        d="M380.185 91.0073H301.017C297.485 91.0073 294.625 93.8672 294.625 97.399V134.061C294.625 137.593 297.485 140.453 301.017 140.453H371.942L381.42 153.681C382.608 155.346 384.53 156.33 386.569 156.33V97.399C386.577 93.8672 383.717 91.0073 380.185 91.0073ZM318.48 118.098C316.941 118.098 315.691 116.847 315.691 115.308C315.691 113.769 316.941 112.519 318.48 112.519C320.02 112.519 321.27 113.769 321.27 115.308C321.27 116.855 320.02 118.098 318.48 118.098ZM339.132 118.098C337.593 118.098 336.343 116.847 336.343 115.308C336.343 113.769 337.593 112.519 339.132 112.519C340.671 112.519 341.922 113.769 341.922 115.308C341.922 116.855 340.671 118.098 339.132 118.098ZM359.784 118.098C358.244 118.098 356.994 116.847 356.994 115.308C356.994 113.769 358.244 112.519 359.784 112.519C361.323 112.519 362.573 113.769 362.573 115.308C362.565 116.855 361.315 118.098 359.784 118.098Z"
        fill="#EB1F24"
      />
      <path
        d="M374.896 85.186H295.72C292.188 85.186 289.328 88.0459 289.328 91.5777V128.24C289.328 131.771 292.188 134.631 295.72 134.631H366.645L376.123 147.86C377.311 149.524 379.233 150.509 381.272 150.509V91.5777C381.28 88.0459 378.42 85.186 374.896 85.186ZM313.191 112.276C311.652 112.276 310.402 111.026 310.402 109.487C310.402 107.947 311.652 106.697 313.191 106.697C314.731 106.697 315.981 107.947 315.981 109.487C315.973 111.026 314.731 112.276 313.191 112.276ZM333.843 112.276C332.304 112.276 331.053 111.026 331.053 109.487C331.053 107.947 332.304 106.697 333.843 106.697C335.382 106.697 336.632 107.947 336.632 109.487C336.625 111.026 335.374 112.276 333.843 112.276ZM354.487 112.276C352.948 112.276 351.697 111.026 351.697 109.487C351.697 107.947 352.948 106.697 354.487 106.697C356.026 106.697 357.276 107.947 357.276 109.487C357.276 111.026 356.026 112.276 354.487 112.276Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M498.548 102.369C498.548 103.908 497.298 105.158 495.758 105.158C494.219 105.158 492.969 103.908 492.969 102.369C492.969 100.829 494.219 99.5791 495.758 99.5791C497.305 99.5869 498.548 100.829 498.548 102.369Z"
        fill="#EB1F24"
      />
      <path
        d="M519.2 102.369C519.2 103.908 517.95 105.158 516.411 105.158C514.871 105.158 513.621 103.908 513.621 102.369C513.621 100.829 514.871 99.5791 516.411 99.5791C517.95 99.5869 519.2 100.829 519.2 102.369Z"
        fill="#EB1F24"
      />
      <path
        d="M539.85 102.369C539.85 103.908 538.6 105.158 537.061 105.158C535.522 105.158 534.271 103.908 534.271 102.369C534.271 100.829 535.522 99.5791 537.061 99.5791C538.6 99.5869 539.85 100.829 539.85 102.369Z"
        fill="#EB1F24"
      />
      <path
        d="M498.548 120.887C498.548 122.426 497.298 123.677 495.758 123.677C494.219 123.677 492.969 122.426 492.969 120.887C492.969 119.348 494.219 118.098 495.758 118.098C497.305 118.098 498.548 119.348 498.548 120.887Z"
        fill="#EB1F24"
      />
      <path
        d="M519.2 120.887C519.2 122.426 517.95 123.677 516.411 123.677C514.871 123.677 513.621 122.426 513.621 120.887C513.621 119.348 514.871 118.098 516.411 118.098C517.95 118.098 519.2 119.348 519.2 120.887Z"
        fill="#EB1F24"
      />
      <path
        d="M539.85 120.887C539.85 122.426 538.6 123.677 537.061 123.677C535.522 123.677 534.271 122.426 534.271 120.887C534.271 119.348 535.522 118.098 537.061 118.098C538.6 118.098 539.85 119.348 539.85 120.887Z"
        fill="#EB1F24"
      />
      <path
        d="M498.548 139.398C498.548 140.937 497.298 142.187 495.758 142.187C494.219 142.187 492.969 140.937 492.969 139.398C492.969 137.859 494.219 136.608 495.758 136.608C497.305 136.616 498.548 137.859 498.548 139.398Z"
        fill="#EB1F24"
      />
      <path
        d="M519.2 139.398C519.2 140.937 517.95 142.187 516.411 142.187C514.871 142.187 513.621 140.937 513.621 139.398C513.621 137.859 514.871 136.608 516.411 136.608C517.95 136.616 519.2 137.859 519.2 139.398Z"
        fill="#EB1F24"
      />
      <path
        d="M539.85 139.398C539.85 140.937 538.6 142.187 537.061 142.187C535.522 142.187 534.271 140.937 534.271 139.398C534.271 137.859 535.522 136.608 537.061 136.608C538.6 136.616 539.85 137.859 539.85 139.398Z"
        fill="#EB1F24"
      />
      <path
        d="M401.861 50.7121C401.861 50.7121 373.255 12.9874 285.921 16.988C198.587 20.9886 169.098 63.6516 218.738 73.5203C267.895 83.2953 298.649 59.862 254.338 50.1417C210.026 40.4214 154.002 64.433 131.139 102.369"
        stroke="black"
        strokeWidth="1.6934"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="13.55 13.55"
      />
      <path
        d="M252.047 114.159H207.844C206.086 114.159 204.672 115.582 204.672 117.332V140.453C204.672 142.211 206.094 143.625 207.844 143.625H241.764L250.624 153.908C251.117 154.478 252.054 154.127 252.054 153.377V143.625C253.812 143.625 255.227 142.203 255.227 140.453V117.332C255.219 115.582 253.805 114.159 252.047 114.159ZM229.941 137.022C225.621 137.022 218.393 124.341 222.698 121.653C226.761 119.113 229.941 123.942 229.941 123.942C229.941 123.942 233.114 119.113 237.185 121.653C241.49 124.349 234.262 137.022 229.941 137.022Z"
        fill="#EB1F24"
      />
      <path
        d="M247.812 117.34H203.61C201.852 117.34 200.438 118.762 200.438 120.512V143.633C200.438 145.391 201.86 146.805 203.61 146.805H237.529L246.39 157.088C246.882 157.658 247.82 157.307 247.82 156.557V146.805C249.578 146.805 250.992 145.383 250.992 143.633V120.512C250.992 118.754 249.57 117.34 247.812 117.34ZM225.707 140.203C221.386 140.203 214.158 127.521 218.464 124.833C222.527 122.294 225.707 127.122 225.707 127.122C225.707 127.122 228.879 122.294 232.95 124.833C237.256 127.521 230.028 140.203 225.707 140.203Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M415.338 14.5813V16.4331L426.629 26.1456C427.785 27.1457 429.582 26.3175 429.582 24.7938V4.37652C429.582 2.84503 427.785 2.02459 426.629 3.02475L415.338 12.7372V14.5813Z"
        fill="#EB1F24"
      />
      <path d="M417.862 11.1121H412.814V18.0585H417.862V11.1121Z" fill="black" />
      <path
        d="M414.809 12.9951V14.8469L426.099 24.5594C427.256 25.5596 429.053 24.7313 429.053 23.2076V2.79034C429.053 1.25885 427.256 0.438411 426.099 1.43857L414.809 11.151V12.9951Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M417.331 9.52588H412.283V16.4723H417.331V9.52588Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M409.439 23.9656H392.827C390.061 23.9656 387.818 21.7231 387.818 18.957V10.2134C387.818 7.44738 390.061 5.20483 392.827 5.20483H409.439C412.205 5.20483 414.448 7.44738 414.448 10.2134V18.957C414.455 21.7231 412.205 23.9656 409.439 23.9656Z"
        fill="#EB1F24"
      />
      <path
        d="M404.609 14.5813C404.609 16.4957 403.054 18.0506 401.139 18.0506C399.225 18.0506 397.67 16.4957 397.67 14.5813C397.67 12.667 399.225 11.1121 401.139 11.1121C403.054 11.1121 404.609 12.667 404.609 14.5813Z"
        fill="white"
      />
      <path
        d="M408.908 22.3794H392.296C389.53 22.3794 387.287 20.1369 387.287 17.3708V8.62726C387.287 5.8612 389.53 3.61865 392.296 3.61865H408.908C411.674 3.61865 413.916 5.8612 413.916 8.62726V17.3708C413.924 20.1369 411.682 22.3794 408.908 22.3794Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M404.079 12.9952C404.079 14.9095 402.524 16.4645 400.61 16.4645C398.696 16.4645 397.141 14.9095 397.141 12.9952C397.141 11.0808 398.696 9.52588 400.61 9.52588C402.524 9.52588 404.079 11.0808 404.079 12.9952Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M176.958 119.277V248.907C176.958 250.368 176.427 251.704 175.551 252.744C174.457 254.033 172.832 254.853 171.012 254.853H41.3662C38.0766 254.853 35.4199 252.189 35.4199 248.907V119.277C35.4199 115.996 38.0844 113.331 41.3662 113.331H171.019C174.293 113.331 176.958 115.996 176.958 119.277Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M176.958 119.277V130.193H35.4121V119.277C35.4121 115.996 38.0766 113.331 41.3584 113.331H171.012C174.293 113.331 176.958 115.996 176.958 119.277Z"
        fill="#1F1F1F"
      />
      <path
        d="M137.772 122.036C137.772 123.575 136.522 124.825 134.983 124.825C133.444 124.825 132.193 123.575 132.193 122.036C132.193 120.496 133.444 119.246 134.983 119.246C136.522 119.246 137.772 120.496 137.772 122.036Z"
        fill="white"
      />
      <path
        d="M155.204 122.036C155.204 123.575 153.954 124.825 152.414 124.825C150.875 124.825 149.625 123.575 149.625 122.036C149.625 120.496 150.875 119.246 152.414 119.246C153.954 119.246 155.204 120.496 155.204 122.036Z"
        fill="white"
      />
      <path
        d="M172.638 122.036C172.638 123.575 171.387 124.825 169.848 124.825C168.309 124.825 167.059 123.575 167.059 122.036C167.059 120.496 168.309 119.246 169.848 119.246C171.387 119.246 172.638 120.496 172.638 122.036Z"
        fill="white"
      />
      <path
        d="M172.638 122.036C172.638 123.575 171.387 124.825 169.848 124.825C168.309 124.825 167.059 123.575 167.059 122.036C167.059 120.496 168.309 119.246 169.848 119.246C171.387 119.246 172.638 120.496 172.638 122.036Z"
        fill="#EB1F24"
      />
      <path
        d="M176.958 119.277V130.193H35.4121V119.277C35.4121 115.996 38.0766 113.331 41.3584 113.331H171.012C174.293 113.331 176.958 115.996 176.958 119.277Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.866 87.9571C57.444 88.2227 57.0221 88.4649 56.5845 88.6681H43.1761V82.2999H46.3251L38.6676 73.5485L31.0024 82.2999H34.1591V88.6681H19.8756C17.4612 85.9411 16 82.3624 16 78.4399C16 76.549 16.3438 74.7362 16.9689 73.0563C19.1489 67.1804 24.8061 63 31.4399 63C35.9485 63 40.0038 64.9378 42.8245 68.0164C43.9341 67.0006 45.4734 66.3755 47.169 66.3755C50.5367 66.3755 53.2715 68.8291 53.2715 71.8608V71.8998C56.6783 71.4232 59.9757 72.65 61.7181 75.3691C62.2026 76.1193 62.5307 76.9319 62.7105 77.7758C63.4996 81.3779 61.6478 85.527 57.866 87.9571Z"
        fill="#EB1F24"
      />
      <path
        d="M60.5144 89.8091C60.0924 90.0748 59.6705 90.317 59.233 90.5202H45.8246V84.152H48.9735L41.3161 75.4006L33.6508 84.152H36.8075V90.5202H22.524C20.1096 87.7932 18.6484 84.2145 18.6484 80.292C18.6484 78.4011 18.9922 76.5883 19.6173 74.9083C21.7974 69.0324 27.4545 64.8521 34.0884 64.8521C38.5969 64.8521 42.6522 66.7898 45.4729 69.8685C46.5825 68.8527 48.1218 68.2276 49.8174 68.2276C53.1851 68.2276 55.9199 70.6811 55.9199 73.7128V73.7519C59.3267 73.2752 62.6241 74.502 64.3666 77.2212C64.851 77.9713 65.1792 78.7839 65.3589 79.6278C66.1481 83.2299 64.2962 87.379 60.5144 89.8091Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M192.686 15.5266L195.608 22.0042L215.736 2.94653L192.686 15.5266Z" fill="#EB1F24" />
      <path
        d="M192.686 15.5266L195.608 22.0042L215.736 2.94653L192.686 15.5266Z"
        stroke="black"
        strokeWidth="1.0161"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M193.764 15.5655L195.608 22.004L192.686 15.5264L192.365 15.3624L193.78 15.0342L193.764 15.5655Z"
        fill="black"
      />
      <path
        d="M215.735 2.94653L206.21 16.097L193.763 15.5657L192.685 15.5266L192.364 15.3625L186.137 12.1589L215.735 2.94653Z"
        fill="#EB1F24"
      />
      <path
        d="M215.735 2.94653L206.21 16.097L193.763 15.5657L192.685 15.5266L192.364 15.3626L186.137 12.1589L215.735 2.94653Z"
        stroke="black"
        strokeWidth="1.0161"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M215.736 2.94653L193.764 15.5657L192.686 15.5266L192.365 15.3625L215.736 2.94653Z"
        fill="black"
      />
      <path
        d="M175.825 182.092H172.73V164.753H173.606C174.832 164.753 175.825 165.746 175.825 166.98V182.092Z"
        fill="white"
      />
      <path
        d="M156.863 167.191L144.541 182.373H151.651C151.612 180.842 152.027 179.334 152.855 178.044L156.863 171.77V167.191Z"
        fill="white"
      />
      <path
        d="M175.825 182.092H172.73V164.753H173.606C174.832 164.753 175.825 165.746 175.825 166.98V182.092Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M156.863 167.191L144.541 182.373H151.651C151.612 180.842 152.027 179.334 152.855 178.044L156.863 171.77V167.191Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M168.675 160.463H160.674V182.092H168.675V160.463Z" fill="#1F1F1F" />
      <path
        d="M168.675 160.463H160.674V182.092H168.675V160.463Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M161.628 157.987H155.432V182.092H161.628V157.987Z" fill="#EB1F24" />
      <path
        d="M161.628 157.987H155.432V182.092H161.628V157.987Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M173.825 157.987H167.629V182.092H173.825V157.987Z" fill="#EB1F24" />
      <path
        d="M173.825 157.987H167.629V182.092H173.825V157.987Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M176.967 181.42H129.334V184.092H176.967V181.42Z" fill="white" />
      <path
        d="M176.967 181.42H129.334V184.092H176.967V181.42Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.8311 246.047V294.031H45.7812"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.8311 272.067H45.7812"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.434 274.974H1"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.7223 268.121H20.8145"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.434 297.04H1"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.7223 290.179H20.8145"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M48.0215 265.644H35.4102V278.256H48.0215V265.644Z" fill="#EB1F24" />
      <path d="M48.0215 287.726H35.4102V300.337H48.0215V287.726Z" fill="#EB1F24" />
      <path
        d="M46.1719 263.261H33.5605V275.872H46.1719V263.261Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.1719 285.343H33.5605V297.954H46.1719V285.343Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M368.55 181.693C368.55 181.693 360.268 157.893 360.26 155.4C360.252 152.001 365.057 149.97 367.98 151.587C372.23 153.939 378.747 183.217 378.747 183.217L368.55 181.693Z"
        fill="white"
      />
      <path
        d="M368.55 181.693C368.55 181.693 360.268 157.893 360.26 155.4C360.252 152.001 365.057 149.97 367.98 151.587C372.23 153.939 378.747 183.217 378.747 183.217L368.55 181.693Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M386.577 190.015C376.333 198.469 363.628 199.431 358.268 199.423C356.252 189.179 354.838 181.037 354.838 181.037L379.99 175.583C379.99 175.575 382.655 181.287 386.577 190.015Z"
        fill="white"
      />
      <path
        d="M386.577 190.015C376.333 198.469 363.628 199.431 358.268 199.423C356.252 189.179 354.838 181.037 354.838 181.037L379.99 175.583C379.99 175.575 382.655 181.287 386.577 190.015Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M356.645 174.646C356.645 174.646 357.504 171.028 361.411 169.879C365.318 168.738 368.935 176.169 369.795 180.264C370.655 184.358 369.983 187.882 366.177 187.984C362.364 188.077 361.317 183.506 360.559 180.842C359.793 178.169 356.645 174.646 356.645 174.646Z"
        fill="white"
      />
      <path
        d="M356.645 174.646C356.645 174.646 357.504 171.028 361.411 169.879C365.318 168.738 368.935 176.169 369.795 180.264C370.655 184.358 369.983 187.882 366.177 187.984C362.364 188.077 361.317 183.506 360.559 180.842C359.793 178.169 356.645 174.646 356.645 174.646Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M449.07 207.323L411.619 242V242.008L409.431 244.774C406.493 247.79 400.375 252.166 389.709 251.916C373.816 251.541 366.909 237.765 362.877 221.044L370.464 215.144C374.754 206.276 381.286 199.642 388.662 194.672C410.439 179.998 439.514 179.889 439.514 179.889L449.07 207.323Z"
        fill="#1F1F1F"
      />
      <path
        d="M449.07 207.323L411.619 242V242.008L409.431 244.774C406.493 247.79 400.375 252.166 389.709 251.916C373.816 251.541 366.909 237.765 362.877 221.044L370.464 215.144C374.754 206.276 381.286 199.642 388.662 194.672C410.439 179.998 439.514 179.889 439.514 179.889L449.07 207.323Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M409.426 244.774C406.488 247.79 400.37 252.166 389.704 251.915C373.811 251.54 366.904 237.765 362.872 221.043C362.794 220.731 362.724 220.411 362.645 220.098C362.333 218.754 362.02 217.371 361.708 215.98C361.536 215.207 361.364 214.433 361.2 213.659C361.059 213.027 360.926 212.394 360.794 211.769C360.645 211.089 360.504 210.401 360.356 209.714C360.075 208.393 359.809 207.08 359.543 205.783C359.457 205.369 359.371 204.955 359.285 204.541C359.254 204.361 359.215 204.181 359.176 204.002C359.121 203.728 359.067 203.463 359.012 203.197C358.91 202.697 358.817 202.205 358.715 201.712C358.645 201.361 358.574 201.017 358.512 200.665C358.426 200.243 358.348 199.837 358.27 199.423C358.199 199.063 358.129 198.696 358.059 198.337C358.004 198.063 357.949 197.798 357.902 197.524C357.887 197.462 357.879 197.391 357.863 197.329C357.817 197.102 357.777 196.876 357.731 196.649C357.731 196.633 357.731 196.618 357.723 196.602C357.668 196.297 357.606 195.993 357.551 195.688C357.496 195.414 357.441 195.149 357.395 194.883C357.34 194.586 357.277 194.297 357.223 194.008C362.856 194.352 372.522 193.094 383.828 183.928C383.899 184.084 383.969 184.241 384.039 184.397C384.141 184.624 384.25 184.858 384.36 185.1C384.446 185.288 384.532 185.483 384.625 185.678C385.235 187.022 385.891 188.476 386.587 190.023C387.243 191.492 387.938 193.047 388.665 194.68C394.9 208.713 403.277 228.162 409.426 244.774Z"
        fill="#1F1F1F"
      />
      <path
        d="M409.426 244.774C406.488 247.79 400.37 252.166 389.704 251.915C373.811 251.54 366.904 237.765 362.872 221.043C362.794 220.731 362.724 220.411 362.645 220.098C362.333 218.754 362.02 217.371 361.708 215.98C361.536 215.207 361.364 214.433 361.2 213.659C361.059 213.027 360.926 212.394 360.794 211.769C360.645 211.089 360.504 210.401 360.356 209.714C360.075 208.393 359.809 207.08 359.543 205.783C359.457 205.369 359.371 204.955 359.285 204.541C359.254 204.361 359.215 204.181 359.176 204.002C359.121 203.728 359.067 203.463 359.012 203.197C358.91 202.697 358.817 202.205 358.715 201.712C358.645 201.361 358.574 201.017 358.512 200.665C358.426 200.243 358.348 199.837 358.27 199.423C357.895 197.532 357.551 195.719 357.223 194.008C362.856 194.352 372.522 193.094 383.828 183.928C385.18 186.905 386.829 190.554 388.657 194.672C390.775 199.431 393.135 204.807 395.572 210.487"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M386.579 190.015C376.335 198.47 363.63 199.431 358.27 199.423C358.199 199.063 358.129 198.696 358.059 198.337C358.004 198.063 357.949 197.798 357.902 197.524C357.887 197.462 357.879 197.391 357.863 197.329C357.824 197.102 357.777 196.876 357.731 196.649C357.731 196.633 357.731 196.618 357.723 196.602C357.668 196.297 357.606 195.993 357.551 195.688C357.496 195.414 357.449 195.149 357.395 194.883C357.34 194.586 357.277 194.297 357.223 194.008C362.856 194.352 372.522 193.094 383.828 183.928C383.899 184.084 383.969 184.241 384.039 184.397C384.141 184.624 384.25 184.858 384.36 185.1C384.446 185.288 384.532 185.483 384.625 185.678C385.227 187.007 385.883 188.46 386.579 190.015Z"
        fill="#EB1F24"
      />
      <path
        d="M386.579 190.015C376.335 198.47 363.63 199.431 358.27 199.423C358.199 199.063 358.129 198.696 358.059 198.337C358.004 198.063 357.949 197.798 357.902 197.524C357.887 197.462 357.879 197.391 357.863 197.329C357.824 197.102 357.777 196.876 357.731 196.649C357.731 196.633 357.731 196.618 357.723 196.602C357.668 196.297 357.606 195.993 357.551 195.688C357.496 195.414 357.449 195.149 357.395 194.883C357.34 194.586 357.277 194.297 357.223 194.008C362.856 194.352 372.522 193.094 383.828 183.928C383.899 184.084 383.969 184.241 384.039 184.397C384.141 184.624 384.25 184.858 384.36 185.1C384.446 185.288 384.532 185.483 384.625 185.678C385.227 187.007 385.883 188.46 386.579 190.015Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M352.072 176.841C351.275 179.545 353.979 189.89 359.886 188.655C365.793 187.421 360.573 175.693 357.315 173.981C355.315 172.934 352.549 175.216 352.072 176.841Z"
        fill="white"
      />
      <path
        d="M352.072 176.841C351.275 179.545 353.979 189.89 359.886 188.655C365.793 187.421 360.573 175.693 357.315 173.981C355.315 172.934 352.549 175.216 352.072 176.841Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M380.536 176.755C380.536 176.755 379.7 171.692 376.559 171.317C373.418 170.934 362.698 177.318 363.385 179.99C364.073 182.655 366.182 184.881 370.128 183.709C374.067 182.522 380.536 176.755 380.536 176.755Z"
        fill="white"
      />
      <path
        d="M380.536 176.755C380.536 176.755 379.7 171.692 376.559 171.317C373.418 170.934 362.698 177.318 363.385 179.99C364.073 182.655 366.182 184.881 370.128 183.709C372.058 183.131 374.59 181.459 376.676 179.896"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M439.509 179.889C439.509 179.889 468.396 175.318 486.118 196.844C503.839 218.379 505.425 247.563 505.808 255.752L400.565 255.721C400.565 255.721 395.838 237.46 403.23 216.848C410.629 196.243 439.509 179.889 439.509 179.889Z"
        fill="#1F1F1F"
      />
      <path
        d="M439.509 179.889C439.509 179.889 468.396 175.318 486.118 196.844C503.839 218.379 505.425 247.563 505.808 255.752L400.565 255.721C400.565 255.721 395.838 237.46 403.23 216.848"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M475.615 217.926C475.615 217.926 478.686 231.623 477.545 256.487"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M449.073 168.973V183.358C449.073 183.358 446.479 188.007 439.876 188.007C433.282 188.007 430.672 183.358 430.672 183.358V168.973H449.073Z"
        fill="#EB1F24"
      />
      <path
        d="M449.073 168.973V183.358C449.073 183.358 446.479 188.007 439.876 188.007C433.282 188.007 430.672 183.358 430.672 183.358V168.973H449.073Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M447.665 167.512V179.693C447.665 179.693 445.469 183.631 439.882 183.631C434.295 183.631 432.092 179.693 432.092 179.693V167.512H447.665Z"
        fill="white"
      />
      <path
        d="M447.665 167.512V179.693C447.665 179.693 445.469 183.631 439.882 183.631C434.295 183.631 432.092 179.693 432.092 179.693V167.512H447.665Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M473.16 191.382C466.683 198.751 452.579 199.001 447.313 188.718C442.038 178.427 447.664 154.423 447.664 154.423L427.302 154.548C427.302 154.548 438.35 169.535 432.084 184.78C427.309 196.391 416.862 200.337 410.135 195.946C408.033 194.578 406.29 192.383 405.204 189.359C400.633 176.654 412.698 170.942 417.144 166.238C421.59 161.542 419.3 151.626 419.05 143.25C418.792 134.866 424.145 119.301 438.428 118.605C451.579 117.972 461.604 130.295 460.205 148.079C458.807 165.863 464.526 168.785 468.081 171.582C471.637 174.364 479.638 184.014 473.16 191.382Z"
        fill="#1F1F1F"
      />
      <path
        d="M420.699 159.503C421.644 168.59 411.807 173.223 408.76 177.654C405.712 182.084 405.204 189.343 410.135 195.93C408.033 194.563 406.29 192.367 405.204 189.343C400.633 176.638 412.698 170.926 417.144 166.222C421.59 161.526 419.3 151.611 419.05 143.234C418.792 134.85 424.145 119.285 438.428 118.59C418.738 122.653 419.761 150.415 420.699 159.503Z"
        fill="white"
      />
      <path
        d="M438.422 118.598C424.147 119.285 418.794 134.858 419.044 143.242C419.294 151.626 421.584 161.534 417.138 166.23C412.692 170.934 400.627 176.646 405.198 189.351C409.77 202.056 425.811 200.024 432.078 184.78C438.352 169.535 427.296 154.549 427.296 154.549L447.651 154.424C447.651 154.424 442.025 178.435 447.299 188.718C452.573 199.009 466.669 198.751 473.147 191.383C479.624 184.014 471.623 174.364 468.068 171.567C464.512 168.77 458.793 165.847 460.192 148.063C461.606 130.279 451.573 117.957 438.422 118.598Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M455.175 152.923C455.175 164.003 448.158 172.419 439.508 172.419C430.851 172.419 423.842 164.003 423.842 152.923C423.842 141.843 430.859 131.139 439.508 131.139C448.166 131.139 455.175 141.836 455.175 152.923Z"
        fill="white"
      />
      <path
        d="M455.175 152.923C455.175 164.003 448.158 172.419 439.508 172.419C430.851 172.419 423.842 164.003 423.842 152.923C423.842 141.843 430.859 131.139 439.508 131.139C448.166 131.139 455.175 141.836 455.175 152.923Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M433.641 152.306H431.914V149.626C431.914 149.149 432.297 148.767 432.774 148.767C433.25 148.767 433.633 149.149 433.633 149.626V152.306H433.641Z"
        fill="#1F1F1F"
      />
      <path
        d="M443.422 145.141C443.508 146.118 448.602 146.727 448.751 145.602C448.899 144.477 443.305 143.766 443.422 145.141Z"
        fill="#1F1F1F"
      />
      <path
        d="M445.844 152.306H444.117V149.626C444.117 149.149 444.5 148.767 444.977 148.767C445.453 148.767 445.836 149.149 445.836 149.626V152.306H445.844Z"
        fill="#1F1F1F"
      />
      <path
        d="M443.883 152.306C443.883 152.306 444.821 151.571 446.079 152.306"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M434.313 144.937C434.305 145.914 429.273 146.922 429.039 145.813C428.804 144.695 434.321 143.554 434.313 144.937Z"
        stroke="black"
        strokeWidth="0.4255"
        strokeMiterlimit={10}
      />
      <path
        d="M433.873 152.306C433.873 152.306 432.936 151.571 431.678 152.306"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M438.188 157.346C438.188 157.346 437.829 159.182 439.61 158.995C441.392 158.815 441.438 156.885 440.157 156.838"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M444.642 132.428C444.642 132.428 438.485 149.892 422.858 151.775C422.858 151.775 418.927 125.833 438.423 124.435C457.918 123.036 456.519 151.587 456.519 151.587C456.519 151.587 450.932 150.97 444.642 132.428Z"
        fill="#1F1F1F"
      />
      <path
        d="M444.642 132.428C444.642 132.428 438.485 149.892 422.858 151.775C422.858 151.775 418.927 125.833 438.423 124.435C457.918 123.036 456.519 151.587 456.519 151.587C456.519 151.587 450.932 150.97 444.642 132.428Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M453.682 151.587C453.682 151.587 455.08 149.204 459.081 150.415C463.081 151.618 461.941 161.846 453.682 159.627V151.587Z"
        fill="white"
      />
      <path
        d="M454.713 150.65C455.549 150.157 456.94 149.774 459.081 150.423C462.995 151.603 461.988 161.401 454.221 159.76"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M456.012 153.884C456.012 153.884 458.301 152.298 459.567 155.01"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M457.408 156.377V153.423"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M444.353 165.667C443.876 165.808 443.337 165.855 442.798 165.831C441.118 165.761 439.438 164.949 439.54 163.683C439.704 161.651 443.376 162.284 444.353 160.76C445.337 159.237 447.314 158.916 447.666 160.76C447.674 160.792 447.674 160.823 447.681 160.854C447.986 162.683 447.197 164.847 444.353 165.667Z"
        fill="#1F1F1F"
      />
      <path
        d="M444.353 165.667C443.876 165.808 443.337 165.855 442.798 165.831C441.118 165.761 439.438 164.949 439.54 163.683C439.704 161.651 443.376 162.284 444.353 160.76C445.337 159.237 447.314 158.916 447.666 160.76C447.674 160.792 447.674 160.823 447.681 160.854C447.986 162.683 447.197 164.847 444.353 165.667Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M444.354 165.667C443.877 165.808 443.338 165.855 442.799 165.832C442.549 164.386 442.416 162.542 443.885 162.097C445.041 161.745 445.682 160.049 447.682 160.854C447.987 162.683 447.198 164.847 444.354 165.667Z"
        fill="white"
      />
      <path
        d="M425.162 151.587C425.162 151.587 423.764 149.204 419.763 150.415C415.762 151.618 416.903 161.846 425.162 159.627V151.587Z"
        fill="white"
      />
      <path
        d="M424.897 151.587C424.897 151.587 423.498 149.204 419.497 150.415C415.497 151.618 416.637 161.846 424.897 159.627"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M422.835 153.884C422.835 153.884 420.545 152.298 419.279 155.01"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M421.436 156.377V153.423"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.384 210.386V252.752H43.1777V212.237C43.1777 210.104 43.9513 208.143 45.2249 206.627C46.8189 204.736 49.2099 203.533 51.8744 203.533H102.937C107 203.533 110.407 206.315 111.368 210.081C111.384 210.182 111.384 210.284 111.384 210.386Z"
        fill="#EB1F24"
      />
      <path
        d="M111.384 210.386V252.752H42.9199V210.386C42.9199 208.252 43.6935 206.291 44.9671 204.775C46.5611 202.884 48.9521 201.681 51.6166 201.681H102.679C107.383 201.681 111.22 205.416 111.376 210.081C111.384 210.182 111.384 210.284 111.384 210.386Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.3547 252.744H42.9121V234.28C49.6944 215.988 64.1264 213.621 64.1264 213.621C64.1264 213.621 66.4158 219.082 70.4008 224.036C74.3936 228.99 72.3855 234.452 74.4171 244.868C75.0891 248.306 75.3469 250.806 75.3547 252.744Z"
        fill="black"
      />
      <path
        d="M77.1518 203.533V212.605C71.8619 210.378 69.3849 206.846 68.291 203.533H77.1518Z"
        fill="black"
      />
      <path
        d="M67.8149 227.974V252.744H59.923C57.321 252.744 54.8988 251.306 53.7501 248.97C53.2501 247.946 52.875 246.696 52.8281 245.211C52.8281 245.094 52.8203 244.985 52.8203 244.867C52.8203 239.406 52.8203 227.974 52.8203 227.974H67.8149Z"
        fill="white"
      />
      <path
        d="M67.8149 252.744H59.923C57.321 252.744 54.8988 251.306 53.7501 248.97C53.2501 247.946 52.875 246.696 52.8281 245.211C52.8281 245.094 52.8203 244.985 52.8203 244.867C52.8203 242 52.8203 237.491 52.8203 233.858"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.6509 252.619H59.8292C57.5085 252.619 55.3207 251.47 54.0549 249.524C54.0393 249.501 54.0236 249.478 54.008 249.454C53.3048 248.36 52.9531 247.079 52.9531 245.774V229.373L64.2596 229.467L68.1977 229.498L68.6509 252.619Z"
        fill="white"
      />
      <path
        d="M163.213 243.414C163.213 243.414 166.323 242.273 168.229 243.156C170.136 244.047 173.121 248.173 173.183 249.063C173.246 249.954 171.027 250.907 169.183 249.384C167.339 247.86 163.213 243.414 163.213 243.414Z"
        fill="white"
      />
      <path
        d="M163.213 243.414C163.213 243.414 166.323 242.273 168.229 243.156C170.136 244.047 173.121 248.173 173.183 249.063C173.246 249.954 171.027 250.907 169.183 249.384C167.339 247.86 163.213 243.414 163.213 243.414Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M157.625 243.539C157.625 243.539 159.086 241 161.563 241.383C164.04 241.765 169.408 246.907 170.025 247.837C171.088 249.446 168.767 251.853 166.314 251.533C163.868 251.212 158.195 246.047 158.195 246.047L157.625 243.539Z"
        fill="white"
      />
      <path
        d="M157.625 243.539C157.625 243.539 159.086 241 161.563 241.383C164.04 241.765 169.408 246.907 170.025 247.837C171.088 249.446 168.767 251.853 166.314 251.533C163.868 251.212 158.195 246.047 158.195 246.047L157.625 243.539Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M128.41 252.744H68.0727L67.6897 242.961C61.2122 234.327 50.9215 233.561 50.9215 233.561C50.7965 231.022 53.9688 219.59 63.2437 212.472C68.0648 208.768 75.902 207.44 82.3014 207.018C88.2086 206.627 92.889 207.01 92.889 207.01C96.8975 207.447 100.461 208.198 103.641 209.198C134.653 218.988 128.41 252.744 128.41 252.744Z"
        fill="#EB1F24"
      />
      <path
        d="M128.41 252.744H68.0727L67.6897 242.961C61.2122 234.327 50.9215 233.561 50.9215 233.561C50.7965 231.022 53.9688 219.59 63.2437 212.472C68.0648 208.768 75.902 207.44 82.3014 207.018C88.2086 206.627 92.889 207.01 92.889 207.01C96.8975 207.447 100.461 208.198 103.641 209.198C134.653 218.988 128.41 252.744 128.41 252.744Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.383 244.875C111.383 244.875 110.242 251.353 110.242 252.752H144.669L155.21 247.54C155.21 247.54 160.547 251.095 162.454 251.603C162.454 251.603 166.392 252.236 165.501 248.173C165.501 248.173 156.038 240.742 154.132 239.976C152.225 239.21 146.006 241.179 141.997 243.594L128.026 239.335L111.383 244.875Z"
        fill="white"
      />
      <path
        d="M111.383 244.875C111.383 244.875 110.242 251.353 110.242 252.752H144.669L155.21 247.54C155.21 247.54 160.547 251.095 162.454 251.603C162.454 251.603 166.392 252.236 165.501 248.173C165.501 248.173 156.038 240.742 154.132 239.976C152.225 239.21 146.006 241.179 141.997 243.594L128.026 239.335L111.383 244.875Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M110.242 233.944L111.383 244.875"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.64 209.206V213.613C103.64 215.574 102.89 217.465 101.523 218.863C99.8505 220.575 97.0765 222.395 92.8884 222.387C88.3799 222.38 85.731 220.723 84.2073 219.074C82.9493 217.715 82.293 215.894 82.293 214.042V207.026C88.2001 206.635 92.8806 207.018 92.8806 207.018C96.8969 207.455 100.46 208.205 103.64 209.206Z"
        fill="black"
      />
      <path
        d="M138.316 249.634C138.316 249.634 142.505 253.306 146.13 254.08C149.748 254.853 153.491 252.29 153.241 250.462C152.991 248.634 143.145 246.649 143.145 246.649L138.316 249.634Z"
        fill="white"
      />
      <path
        d="M139.85 250.853C141.397 251.994 143.874 253.595 146.124 254.08C149.742 254.853 153.485 252.291 153.235 250.462C153.086 249.376 149.523 248.227 146.671 247.477"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.766 201.681V212.667C99.766 213.839 99.2112 214.949 98.2423 215.621C96.1638 217.066 92.1241 218.801 87.1389 215.566C86.1075 214.894 85.4746 213.745 85.4746 212.519V201.681H99.766Z"
        fill="white"
      />
      <path
        d="M99.766 201.681V212.667C99.766 213.839 99.2112 214.949 98.2423 215.621C96.1638 217.066 92.1241 218.801 87.1389 215.566C86.1075 214.894 85.4746 213.745 85.4746 212.519V201.681H99.766Z"
        stroke="black"
        strokeWidth="1.0161"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.68 190.742C104.68 199.618 99.0617 206.361 92.1309 206.361C85.2001 206.361 79.582 199.626 79.582 190.742C79.582 181.857 85.2001 173.286 92.1309 173.286C99.0617 173.286 104.68 181.857 104.68 190.742Z"
        fill="white"
      />
      <path
        d="M104.68 190.742C104.68 199.618 99.0617 206.361 92.1309 206.361C85.2001 206.361 79.582 199.626 79.582 190.742C79.582 181.857 85.2001 173.286 92.1309 173.286C99.0617 173.286 104.68 181.857 104.68 190.742Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.7944 188.171C96.3961 188.679 99.6232 186.131 99.0684 185.413C98.5214 184.686 94.9505 187.452 95.7944 188.171Z"
        fill="black"
      />
      <path
        d="M97.2034 192.359H95.8203V190.21C95.8203 189.827 96.1329 189.523 96.5079 189.523C96.8908 189.523 97.1955 189.835 97.1955 190.21V192.359H97.2034Z"
        fill="black"
      />
      <path
        d="M87.4279 192.359H86.0449V190.21C86.0449 189.827 86.3574 189.523 86.7325 189.523C87.1154 189.523 87.4201 189.835 87.4201 190.21V192.359H87.4279Z"
        fill="black"
      />
      <path
        d="M95.6309 192.359C95.6309 192.359 96.381 191.765 97.3889 192.359"
        stroke="black"
        strokeWidth="1.0161"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.5606 188.038C86.998 188.593 83.5834 186.304 84.0757 185.546C84.5758 184.78 88.3498 187.265 87.5606 188.038Z"
        fill="black"
      />
      <path
        d="M87.6233 192.359C87.6233 192.359 86.8732 191.765 85.8652 192.359"
        stroke="black"
        strokeWidth="1.0161"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.0767 194.281C91.0767 194.281 90.7876 195.75 92.2175 195.602C93.6474 195.453 93.6865 193.914 92.6551 193.875"
        stroke="black"
        strokeWidth="1.0161"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M107.719 187.538C110.329 188.851 109.571 195.492 104.305 195.016"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.828 191.711C104.828 191.711 106.18 190.351 107.375 192.031"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.2625 196.711C87.2625 196.711 89.1221 199.477 92.5523 199.329C92.5523 199.329 90.0754 202.236 87.614 200.618C86.0591 199.587 87.2625 196.711 87.2625 196.711Z"
        fill="black"
      />
      <path
        d="M87.2625 196.711C87.2625 196.711 89.1221 199.477 92.5523 199.329C92.5523 199.329 90.0754 202.236 87.614 200.618C86.0591 199.587 87.2625 196.711 87.2625 196.711Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M102.952 193.693C105.256 191.389 105.256 187.654 102.952 185.35C100.648 183.046 96.9128 183.046 94.609 185.35C92.3051 187.654 92.3051 191.389 94.609 193.693C96.9128 195.997 100.648 195.997 102.952 193.693Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.3123 190.471C91.8343 187.255 89.6502 184.224 86.4341 183.702C83.218 183.18 80.1877 185.364 79.6658 188.581C79.1438 191.797 81.3279 194.827 84.544 195.349C87.7601 195.871 90.7904 193.687 91.3123 190.471Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.6305 210.831C86.9976 215.91 83.1845 228.998 77.9727 240.429C72.761 251.861 68.6978 252.626 68.6978 252.626H59.8214C57.5007 252.626 55.3129 251.478 54.0471 249.532C54.0314 249.509 54.0158 249.485 54.0002 249.462C53.297 248.368 52.9453 247.087 52.9453 245.782V244.516C56.3443 239.867 60.4778 234.405 64.2518 229.474C70.0574 221.887 74.9879 215.597 74.9879 215.597C74.9879 215.597 75.3707 209.565 74.9879 208.549C74.605 207.533 71.0497 202.197 70.3699 200.673C69.6901 199.149 69.0807 190.89 69.9011 189.179C70.7294 187.468 72.1905 188.226 72.1905 188.226L74.7925 192.797C74.7925 192.797 77.5273 189.429 78.7306 187.843C79.9339 186.257 82.2234 186.444 82.7312 189.179C83.2391 191.914 81.0826 193.687 81.1451 194.578C81.2076 195.469 81.4029 197.376 84.2549 200.61C87.1069 203.845 88.2634 205.744 87.6305 210.831Z"
        fill="white"
      />
      <path
        d="M56.0161 240.359C58.6806 236.78 61.5639 232.991 64.2596 229.467C70.0652 221.879 74.9957 215.589 74.9957 215.589C74.9957 215.589 75.3786 209.557 74.9957 208.541C74.6128 207.526 71.0575 202.189 70.3777 200.665C69.6979 199.141 69.0885 190.882 69.9089 189.171C70.7372 187.46 72.1984 188.218 72.1984 188.218L74.8003 192.789C74.8003 192.789 77.5351 189.421 78.7384 187.835C79.9418 186.249 82.2312 186.436 82.7391 189.171C83.247 191.906 81.0904 193.68 81.1529 194.57C81.2154 195.461 81.4107 197.368 84.2628 200.603C87.1148 203.838 88.2634 205.744 87.6305 210.831C86.9976 215.91 83.1845 228.998 77.9727 240.429C72.761 251.861 68.6978 252.626 68.6978 252.626H59.8214C57.5007 252.626 55.3129 251.478 54.0471 249.532C54.0314 249.509 54.0158 249.485 54.0002 249.462C53.297 248.368 52.9453 247.087 52.9453 245.782V244.516"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.509 190.851L104.845 188.374C99.4146 185.131 98.4613 181.607 98.4613 181.607C98.4613 181.607 95.7968 186.655 92.3666 186.655C88.9364 186.655 88.6473 184.092 88.6473 184.092C81.7868 189.023 74.3091 188.327 72.5354 183.998C72.215 183.217 72.0822 182.326 72.1681 181.318C72.7385 174.747 79.0286 174.075 79.0286 174.075C79.0286 174.075 76.8095 171.606 76.6298 169.098C76.5126 167.519 77.2158 165.925 79.7944 164.925C86.3813 162.386 92.8745 166.816 93.0308 166.925C92.9839 166.769 92.1478 163.862 94.656 163.308C97.2268 162.737 99.7975 169.098 99.7975 169.098C99.7975 169.098 101.985 165.972 103.228 166.355C104.47 166.738 102.087 169.598 102.087 169.598C106.658 168.934 108.854 170.551 109.705 179.123C110.565 187.71 105.509 190.851 105.509 190.851Z"
        fill="black"
      />
      <path
        d="M93.0304 166.941C81.5989 163.605 76.6216 169.113 76.6216 169.113C76.5044 167.535 77.2076 165.941 79.7862 164.941C86.3731 162.401 92.8663 166.832 93.0304 166.941Z"
        fill="white"
      />
      <path
        d="M72.5354 184.006C72.215 183.225 72.0822 182.334 72.1681 181.326C72.7385 174.755 79.0286 174.083 79.0286 174.083L79.5834 174.372C72.0353 176.466 72.5354 184.006 72.5354 184.006Z"
        fill="white"
      />
      <path
        d="M105.508 190.851C105.508 190.851 110.555 187.71 109.696 179.13C108.836 170.559 106.649 168.934 102.077 169.606C102.077 169.606 104.461 166.746 103.218 166.363C101.984 165.98 99.7881 169.105 99.7881 169.105C99.7881 169.105 97.2173 162.745 94.6466 163.315C92.0759 163.886 93.0292 166.933 93.0292 166.933C93.0292 166.933 86.4579 162.362 79.7849 164.933C73.1198 167.504 79.0191 174.083 79.0191 174.083C79.0191 174.083 72.7291 174.747 72.1587 181.326C71.5883 187.897 80.5428 189.913 88.6378 184.092C88.6378 184.092 88.927 186.655 92.3572 186.655C95.7874 186.655 98.4519 181.607 98.4519 181.607C98.4519 181.607 99.4052 185.131 104.836 188.374L105.508 190.851Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.1761 199.587C74.1761 199.587 72.1445 196.665 71.8867 194.633C71.6288 192.601 71.8085 186.616 72.3789 185.374C72.8712 184.303 74.7622 183.334 76.028 184.608C76.3874 184.967 76.5671 185.468 76.5437 185.975C76.4734 187.984 76.2858 194.367 76.5671 195.328C76.9031 196.469 78.1377 197.423 78.1377 197.423L74.1761 199.587Z"
        fill="white"
      />
      <path
        d="M74.1761 199.587C74.1761 199.587 72.1445 196.665 71.8867 194.633C71.6288 192.601 71.8085 186.616 72.3789 185.374C72.8712 184.303 74.7622 183.334 76.028 184.608C76.3874 184.967 76.5671 185.468 76.5437 185.975C76.4734 187.984 76.2858 194.367 76.5671 195.328C76.9031 196.469 78.1377 197.423 78.1377 197.423"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.6509 252.619H59.8292C57.5085 252.619 55.3207 251.47 54.0549 249.524C54.0393 249.501 54.0236 249.478 54.008 249.454C53.3048 248.36 52.9531 247.079 52.9531 245.774V233.858"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M541.51 317.371H411.466C408.434 317.371 405.973 314.91 405.973 311.878V244.829C405.973 241.797 408.434 239.335 411.466 239.335H541.51C544.541 239.335 547.003 241.797 547.003 244.829V311.878C546.995 314.918 544.541 317.371 541.51 317.371Z"
        fill="#EB1F24"
      />
      <path
        d="M535.156 313.933H405.112C402.08 313.933 399.619 311.472 399.619 308.44V241.391C399.619 238.359 402.08 235.897 405.112 235.897H525.522H535.156C538.188 235.897 540.649 238.359 540.649 241.391V308.44C540.641 311.472 538.188 313.933 535.156 313.933Z"
        stroke="black"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M434.672 260.737H518.419"
        stroke="white"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M434.672 280.154H518.419"
        stroke="white"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M434.672 299.579H518.419"
        stroke="white"
        strokeWidth="1.3547"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <FacebookIconWrapper>
        <path
          d="M282.128 66.5641C282.128 50.2364 268.892 37 252.564 37C236.236 37 223 50.2362 223 66.5641C223 81.3203 233.811 93.5513 247.945 95.769V75.11H240.438V66.5641H247.945V60.0507C247.945 52.6412 252.359 48.5485 259.111 48.5485C262.346 48.5485 265.729 49.1259 265.729 49.1259V56.4014H262.001C258.329 56.4014 257.183 58.6804 257.183 61.0185V66.5641H265.383L264.072 75.11H257.183V95.769C271.317 93.5513 282.128 81.3205 282.128 66.5641Z"
          fill="#1877F2"
        />
        <path
          d="M264.071 74.9819L265.382 66.436H257.183V60.8904C257.183 58.5521 258.328 56.2734 262.001 56.2734H265.729V48.9978C265.729 48.9978 262.345 48.4204 259.111 48.4204C252.358 48.4204 247.944 52.5132 247.944 59.9227V66.436H240.438V74.9819H247.944V95.641C249.472 95.8804 251.017 96.0005 252.563 96.0001C254.11 96.0006 255.655 95.8805 257.183 95.641V74.9819H264.071Z"
          fill="white"
        />
      </FacebookIconWrapper>
    </svg>
  );
}
