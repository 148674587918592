import styled from "styled-components";

const LinkedinSectionStyle = {
  mainDiv: {
    height: "858px",
    position: "relative",
  },
  circlesLeft: {
    position: "absolute",
    top: "221px",
    zIndex: "0",

    "@media (max-width: 846px)": {
      width: "221px",
    },

    "@media (max-width: 650px)": {
      width: "151px",
      top: "0",
    },
  },
  circlesRight: {
    position: "absolute",
    top: "221px",
    zIndex: "0",
    right: "0",

    "@media (max-width: 846px)": {
      width: "221px",
    },

    "@media (max-width: 650px)": {
      top: "550px",
      width: "151px",
    },
  },
  linkSection: {
    position: "absolute",
    top: "0px",
    right: "0px",
  },
};

export default LinkedinSectionStyle;

export const AnimationText = styled.div`
  overflow: hidden;
`;
