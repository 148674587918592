import styled from "styled-components";
import BG1 from "../../assets/images/bg-1.svg";
import BG2 from "../../assets/images/bg-2.svg";

export const AboutWrap = styled.div`
  padding: 0 3.3%;
  padding-top: 74px;
  background-image: url(${BG1}), url(${BG2});
  background-repeat: no-repeat, no-repeat;
  background-size: cover;
  background-position: bottom;
  padding-bottom: 165px;

  button {
    width: 282px;
    height: 60px;
    background: #eb1f24;
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: white;
    &:hover {
      background-color: #eb1f24;
    }
  }
  @media (max-width: 1199.9px) {
    padding-bottom: 120px;
    button {
      width: 200px;
      height: 40px;
      font-size: 16px;
      line-height: 20px;
      border-radius: 8px;
    }
  }
`;

export const AboutTextWrap = styled.div`
  .MuiListItem-root .MuiTypography-root {
    font-size: 24px;
    line-height: 28px;
    color: #777777;
    margin-bottom: 0;
    margin-top: 0;
    @media (max-width: 1199.9px) {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 0px;
    }
  }
`;

export const AboutTextHeading = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 52px;
  color: #5a4a42;
  margin-bottom: 21px;
  margin-top: 0;
  @media (max-width: 1199.9px) {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 14px;
  }
`;

export const AboutText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32.44px;
  color: #777777;
  margin-bottom: 20px;
  margin-top: 0;
  @media (max-width: 1199.9px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
