import React, { useEffect } from "react";
import EmptyComponent from "../components/EmptyComponent/EmptyComponent";

function AllTogether() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <EmptyComponent />;
}
export default AllTogether;
