import styled from "styled-components";
import BG1 from "../../assets/images/shapes.svg";
import BG2 from "../../assets/images/shapes-2.svg";

export const SocialMediaWrap = styled.section`
  padding: 53px 3.3% 53px;
  background-image: url(${BG1}), url(${BG2});
  background-position: 0 top, right top;
  background-repeat: no-repeat, no-repeat;
  a {
    width: 100%;
    height: 60px;
    background: #ffffff;
    border: 1px solid #eb1f24;
    box-sizing: border-box;
    border-radius: 10px;
    color: #eb1f24;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
  }
  a.active {
    background: #eb1f24;
    color: #fff;
  }
  .MuiBox-root a {
    width: unset;
    border: none;
    height: unset;
    img {
      transition: transform 0.6s ease;
      &:hover {
        transform: scale(1.1);
      }
    }
    img.twitter,
    img.linkedin,
    img.discord,
    img.instagram,
    img.facebook {
      width: 44px;
    }
  }
  @media (max-width: 1199.9px) {
    a {
      height: 40px;
      border-radius: 8px;
      font-size: 16px;
      line-height: 20px;
    }
  }
  @media (max-width: 599.9px) {
    a {
      height: 30px;
      border-radius: 6px;
      font-size: 12px;
      line-height: 16px;
    }
  }
`;
