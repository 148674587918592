import styled from "styled-components";

export const HeadingGoesWrap = styled.section`
  padding: 53px 3.3% 150px;

  a {
    width: 100%;
    height: 60px;
    background: #ffffff;
    border: 1px solid #eb1f24;
    box-sizing: border-box;
    border-radius: 10px;
    color: #eb1f24;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
  }
  a.active {
    background: #eb1f24;
    color: #fff;
  }
  .MuiBox-root a {
    width: unset;
    border: none;
    height: unset;
    img {
      transition: transform 0.6s ease;
      &:hover {
        transform: scale(1.1);
      }
    }
    img.twitter,
    img.linkedin,
    img.discord,
    img.instagram,
    img.facebook {
      width: 50px;
    }
  }
  @media (max-width: 1199.9px) {
    a {
      height: 40px;
      border-radius: 8px;
      font-size: 16px;
      line-height: 20px;
    }
  }
  @media (max-width: 599.9px) {
    a {
      height: 30px;
      border-radius: 6px;
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

export const HeadingGoesHeading = styled.div`
  h1 {
    /* font-family: Roboto; */
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    text-transform: uppercase;
    color: #5a4a42;
    text-align: center;
    margin: 0;
    margin-bottom: 11px;
  }
  @media (max-width: 1199.9px) {
    h1 {
      font-size: 26px;
      line-height: 32px;
    }
  }
`;

export const BordersWrap = styled.div`
  text-align: center;
  span {
    height: 4px;
    display: inline-block;
    width: 30px;
  }
  span:nth-child(1) {
    background: #ababab;
  }
  span:nth-child(2) {
    background: #eb1f24;
  }
  span:nth-child(3) {
    background: #2f1a31;
  }
`;

export const VideoSection = styled.div`
  border-radius: 25px;
  margin-top: 79px;
  margin-bottom: 64px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1199.9px) {
    /* height: 298px;ds */
    margin-top: 59px;
    margin-bottom: 44px;
    padding: 0;
  }
  @media (max-width: 599.9px) {
    height: auto;
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;
