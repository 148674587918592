import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core';
import totalValueAssetsFormStyle from '../TotalValueAssetsComponents/style/totalValueAssetsFormStyle';
import LineChart from './LineChart.js';

const useStyles = makeStyles(totalValueAssetsFormStyle);

export default function TotalValueAssetsForm({ cosData, setCosData }) {
	const classes = useStyles();

	const data1 = [ 0, 10, 40, 22, 26, 32, 25, 45, 18, 21, 12, 24, 7 ];
	const data2 = [ 30, 16, 18, 40, 26, 32, 15, 15, 18, 21, 42, 24, 7 ];
	const data3 = [ 30, 16, 18, 40, 26, 32, 15, 15, 18, 21, 42, 24, 7 ];

	return (
		<div className={classes.formContainer}>
			<LineChart title="Revenu" data1={data1} chartLabel="Royalties" />
			<LineChart title="Learner" data1={data2} chartLabel="number of Learner" />
			<LineChart title="Ampersand" data1={data3} chartLabel="number of ampersand" />
		</div>
	);
}
