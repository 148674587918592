import React, { useEffect } from "react";
import CreateHolder from "../components/CreateHolder/CreateHolder";

function Create() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="creator">
      <CreateHolder />
    </div>
  );
}

export default Create;
