import React from "react";
import { Container, Box, Typography, Grid } from "@mui/material";
import { IconButton, makeStyles } from "@material-ui/core";
import InstagramSectionStyle from "./style";

const useStyles = makeStyles(InstagramSectionStyle);

import post2 from "../../assets/images/post-2.png";
import post3 from "../../assets/images/post-3.png";
import post4 from "../../assets/images/post-4.png";
import { Link } from "@reach/router";
import { BsThreeDotsVertical } from "react-icons/bs";

export default function InstagramSection() {
  const classes = useStyles();

  return (
    <Box
      sx={{
        paddingY: "10%",
        backgroundColor: `white`,
        padding: "79px 0",
        minHeight: "847px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      id="instagram"
    >
      <Container style={{ position: "relative" }}>
        <Box
          sx={{
            textAlign: "center",
            marginBottom: "35px",
          }}
        >
          <div className={classes.linkSection}>
            <Link to="/#instagram">
              <IconButton
                aria-label="Example"
                onClick={() => {
                  navigator.clipboard.writeText(
                    "https://www.lillup.com/instagram"
                  );
                }}
              >
                <BsThreeDotsVertical size={26} color="black" />
              </IconButton>
            </Link>
          </div>
          <Typography
            variant="h2"
            className={classes.sectionTitle}
            style={{ textTransform: "uppercase" }}
          >
            Share Your achievement
          </Typography>
          <Box>
            <Typography
              variant="span"
              sx={{
                height: "4px",
                display: "inline-block",
                width: "30px",
                background: "#ababab",
              }}
            ></Typography>
            <Typography
              variant="span"
              sx={{
                height: "4px",
                display: "inline-block",
                width: "30px",
                background: "#eb1f24",
              }}
            ></Typography>
            <Typography
              variant="span"
              sx={{
                height: "4px",
                display: "inline-block",
                width: "30px",
                background: "#2f1a31",
              }}
            ></Typography>
          </Box>
        </Box>
        <Grid
          container
          spacing={4}
          justifyContent={"center"}
          style={{ marginTop: "58px" }}
        >
          <Grid item xs={12} md={4}>
            <img src={post4} alt="Post" />
          </Grid>
          <Grid item xs={12} md={4}>
            <img src={post2} alt="Post" />
          </Grid>
          <Grid item xs={12} md={4}>
            <img src={post3} alt="Post" />
          </Grid>
        </Grid>
      </Container>
      {/* <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#FADCDC",
                    height: "423.22px",
                    color: "#EB1F24",
                    fontWeight: "400",
                    fontSize: "47px",
                    "@media(min-width: 780px)": {
                      fontSize: "36px",
                    },
                  }}
                >
                  LEAD
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#EB1F24",
                    height: "338.58px",
                    color: "white",
                    fontWeight: "400",
                    fontSize: "47px",
                    marginBottom: "38.42px",
                    "@media(min-width: 780px)": {
                      fontSize: "36px",
                    },
                  }}
                >
                  YOUR
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "black",
                    height: "177.44px",
                    color: "white",
                    fontWeight: "400",
                    fontSize: "47px",
                    "@media(min-width: 780px)": {
                      fontSize: "36px",
                    },
                  }}
                >
                  LIFE
                </Box>
              </Grid>
            </Grid> */}
    </Box>
  );
}
