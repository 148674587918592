import React, { useState } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core';

import PortFolioForm from '../PortFolioComponents/PortFolioForm';
import portfolioHolderStyle from '../PortFolioComponents/style/portfolioHolderStyle';

import Navigate from '../SideNavigate/Navigate';

const useStyles = makeStyles(portfolioHolderStyle);

function PortFolio() {
	const classes = useStyles();

	return (
		<div className={classes.mainContainer}>
			<Navigate />
			<PortFolioForm />
		</div>
	);
}

export default PortFolio;
