const whiteColor = "#FFF";
const blackColor = "#000";
const grayColor = [
  "#999",
  "#3C4858",
  "#eee",
  "#343434",
  "#585858",
  "#232323",
  "#ddd",
  "#6c757d",
  "#333",
  "#212121",
  "#777",
  "#D2D2D2",
  "#AAA",
  "#495057",
  "#e5e5e5",
  "#555",
  "#f9f9f9",
  "#ccc",
  "#444",
  "#f2f2f2",
  "#89229b",
  "#c0c1c2",
  "#9a9a9a",
  "#f5f5f5",
  "#505050",
  "#1f1f1f",
];
const primaryColor = [
  "#9c27b0",
  "#ab47bc",
  "#8e24aa",
  "#af2cc5",
  "#e1bee7",
  "#ba68c8",
];
const hexToRgb = (input) => {
  input = input + "";
  input = input.replace("#", "");
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase(input);
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return (
    parseInt(first, 16) +
    ", " +
    parseInt(second, 16) +
    ", " +
    parseInt(last, 16)
  );
};
const learnerExperienceStyle = (theme) => ({
  contentCenter: {
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: "3",
    transform: "translate(-50%,-50%)",
    textAlign: "center",
    color: "#FFF",
    padding: "0 15px",
    width: "100%",
    maxWidth: "880px",
  },
  container: {
    // this is important!
    // ...container,
    zIndex: "3",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
    },
  },
  title: {
    // ...title,
    fontSize: "13.7em",
    color: "#FFF",
    letterSpacing: "14px !important",
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      fontSize: "7em",
    },
  },
  subTitle: {
    fontSize: "2.25rem",
    marginTop: "0",
    marginBottom: "8px",
  },
  description: {
    fontSize: "1.125rem",
    marginTop: "0",
    marginBottom: "8px",
  },
  pageHeader: {
    backgroundColor: "#FFF",
    color: "#FFF",
    border: "0",
    height: "100%",
    margin: "0",
    display: "flex!important",
    flexDirection: "column",
    paddingLeft: "0",
    paddingRight: "0",
    paddingTop: "0",
    paddingBottom: "0",
    position: "relative",
    minHeight: "100vh",
    alignItems: "top",
    "&:before": {
      background: "rgba(" + hexToRgb("#FFF") + ", 0.5)",
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""',
    },
  },
  block: {
    color: "inherit",
    fontWeight: "500",
    fontSize: "18px",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  left: {
    float: "left!important",
    display: "block",
    "&,& *,& *:hover,& *:focus": {
      color: "inherit !important",
    },
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right",
    "&,& *,& *:hover,& *:focus": {
      color: "inherit !important",
    },
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative",
  },
  footer: {
    position: "absolute",
    width: "100%",
    height: "250px",
    bottom: "0",
    color: "#FFF",
    zIndex: "2",
  },

  portfolioTitleGrp: {
    textAlign: "left",
    width: "100%",

    paddingTop: "20px",
    paddingBottom: "20px",
  },

  portfolioTitle: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "40px",
    lineHeight: "50px",
    color: "#5a4a42",
    fontFamily: "Roboto",
  },

  nftTokenListWrapper: {
    display: "block",
    padding: "0px",
    margin: "0px",
  },

  nftTokenDetailWrapper: {
    display: "block",
    padding: "0px",
    margin: "0px",
  },

  nftTokenWrapper_hidden: {
    display: "none",
  },

  tokenCodeGrp: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0",
  },

  tokenCodeGrp_Img: {
    width: "100%",
    height: "auto",
    cursor: "pointer",

    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "gray",
    borderRadius: "2px",
  },

  walletGroup: {
    display: "flex!important",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "left",

    padding: "0",

    marginTop: "30px",
    marginBottom: "30px",
  },

  walletGroup_Label: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "30px",
    color: "#000000",
  },

  walletGroup_Input: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",

    lineHeight: "30px",

    color: "#000000",
  },

  tokenGroup: {
    display: "flex!important",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "left",

    padding: "0",

    marginTop: "0",
    marginBottom: "30px",
  },

  tokenGroup_Label: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    // fontSize: "1.0rem",
    // fontSize: "1.5vw",

    lineHeight: "30px",
    // lineHeight: "2.1vw",

    color: "#000000",
  },

  tokenGroup_Input: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    // fontSize: "1.0rem",
    // fontSize: "1.5vw",

    lineHeight: "30px",
    // lineHeight: "2.1vw",

    color: "#000000",
  },

  // unused class name(only in Desktop-13)
  token_Id: {
    width: "682px",
    height: "56px",
    backgroundColor: "#FFFFFF",
    backgroundImage:
      "url(" + "assets/img/lillup/exerience/wallet_avarta.png" + ")",
    border: "1px solid #D1D1D1",
    borderRadius: "10px",
    marginTop: "13px",
  },

  publishGrp: {
    display: "flex!important",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    padding: "2px",
    width: "100%",

    marginTop: "30px",
    marginBottom: "50px",
  },

  publishBtn: {
    width: "80%",
    height: "auto",
    backgroundColor: "#eb1f24",
    maxWidth: "400px",
    borderRadius: "10px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
  },

  embed_cell: {
    marginTop: "200px",
    width: "399px",
    height: "703px",
    backgroundColor: "#444444",
    borderRadius: "11px",
  },

  leftPanel: {
    backgroundColor: "#FFF",
    color: "black",
    display: "flex!important",
    flexDirection: "column",
    justifyContent: "top",
    alignItems: "right",
  },

  rightPanel: {
    backgroundColor: "#FFF",
    color: "black",
    display: "flex!important",
    flexDirection: "column",
    justifyContent: "top",
    alignItems: "center",
  },

  socialNetworkTitle: {
    paddingTop: "20px",
    paddingBottom: "20px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "48px",
    color: "black",
  },

  socialNetworkGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },

  socialNetworkRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },

  socialNetworkMark: {
    margin: "2px",

    width: "100px",
    height: "100px",

    cursor: "pointer",
    textAlign: "center",

    color: "black",
    transition: "transform 0.6s ease",
    "&:hover": {
      color: "red",
      fontWeight: "bold",
      transform: "scale(1.0)",
    },

    display: "flex!important",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",

    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    "@media (min-width: 576px)": {
      fontSize: "8px",
    },
    "@media (min-width: 768px)": {
      fontSize: "10px",
    },
    "@media (min-width: 990px)": {
      fontSize: "12px",
    },
    "@media (min-width: 1200px)": {
      fontSize: "14px",
    },
  },

  socialNetworkMark_Img: {
    width: "45px",
    height: "45px",
    transition: "transform 0.6s ease",
  },

  socialNetworkMark_Desc: {
    width: "80px",
    height: "20px",

    "@media (min-width: 576px)": {
      width: "65px",
      height: "14px",
      maxWidth: "65px",
      maxHeight: "14px",
    },
    "@media (min-width: 768px)": {
      width: "70px",
      height: "16px",
      maxWidth: "70px",
      maxHeight: "16px",
    },
    "@media (min-width: 992px)": {
      width: "75px",
      height: "18px",
      maxWidth: "75px",
      maxHeight: "18px",
    },
    "@media (min-width: 1200px)": {
      width: "80px",
      height: "20px",
      maxWidth: "80px",
      maxHeight: "20px",
    },
  },

  qrCodeGroup: {
    display: "flex!important",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    width: "100%",

    padding: "0",
    marginTop: "30px",
  },

  qrCodeGroup_Link: {
    cursor: "pointer",
    padding: "0",
    margin: "0",
  },

  qrCodeGroup_Img: {
    width: "350px",
    height: "350px",

    "@media (min-width: 576px)": {
      width: "200px",
      height: "200px",
      maxWidth: "200px",
      maxHeight: "200px",
    },
    "@media (min-width: 768px)": {
      width: "250px",
      height: "250px",
      maxWidth: "250px",
      maxHeight: "250px",
    },
    "@media (min-width: 990px)": {
      width: "300px",
      height: "300px",
      maxWidth: "300px",
      maxHeight: "300px",
    },
    "@media (min-width: 1200px)": {
      width: "350px",
      height: "350px",
      maxWidth: "350px",
      maxHeight: "350px",
    },
  },

  embeddedGroup: {
    display: "flex!important",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    padding: "2px",
    width: "100%",

    marginTop: "30px",
    marginBottom: "50px",
  },

  embeddedBtn: {
    width: "80%",
    height: "auto",
    backgroundColor: "#eb1f24",
    maxWidth: "400px",
    color: "white",
    borderRadius: "10px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    "&:hover": {
      zIndex: "2",
      boxShadow:
        "0 14px 26px -12px rgb(235 31 36 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(235 31 36 / 20%)",
      backgroundColor: "#eb1f24",
    },
  },

  buttonGroup: {
    position: "relative",
    margin: "10px 1px",
    display: "inline-block",
    verticalAlign: "middle",
    textAlign: "center",
  },

  firstButton: {
    borderTopRightRadius: "0",
    borderBottomRightRadius: "0",
    borderRadius: "30px",
    margin: "0",
    position: "relative",
    float: "left",
    boxShadow:
      "0 2px 2px 0 rgb(235 31 36 / 14%), 0 3px 1px -2px rgb(235 31 36 / 20%), 0 1px 5px 0 rgb(235 31 36 / 12%)",
    backgroundColor: "#eb1f24",
    "&:hover": {
      zIndex: "2",
      boxShadow:
        "0 14px 26px -12px rgb(235 31 36 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(235 31 36 / 20%)",
      backgroundColor: "#eb1f24",
    },
  },

  lastButton: {
    borderTopLeftRadius: "0",
    borderBottomLeftRadius: "0",
    borderRadius: "30px",
    boxShadow:
      "0 2px 2px 0 rgb(235 31 36 / 14%), 0 3px 1px -2px rgb(235 31 36 / 20%), 0 1px 5px 0 rgb(235 31 36 / 12%)",
    backgroundColor: "#eb1f24",
    margin: "0",
    "&:hover": {
      zIndex: "2",
      boxShadow:
        "0 14px 26px -12px rgb(235 31 36 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(235 31 36 / 20%)",
      backgroundColor: "#eb1f24",
    },
  },

  titleEmbed: {
    marginLeft: "25px",
    color: whiteColor,
    fontFamily: "Roboto",
    fontSize: "20px",
  },

  fontStyle: {
    color: "#255FD0",
    fontSize: "14px",
    fontWeight: "bold",
  },

  dialog: {
    backgroundColor: "#212937",
    color: whiteColor,
    width: "500px",
    height: "500px",
    overflow: "visible",
    maxHeight: "unset",
    borderRadius: "6px",

    "@media (min-width: 600px)": {
      margin: "auto",
      maxWidth: "500px",
      marginTop: "130px !important",
    },
  },

  modalHeader: {
    minHeight: "16.43px",
    paddingTop: "24px",
    paddingLeft: "24px",
    borderBottom: "none",
    paddingRight: "24px",
    paddingBottom: "24px",
  },

  modalCloseButton: {
    float: "right",
    border: "0",
    cursor: "pointer",
    margin: "-1rem -1rem -1rem auto",
    opacity: ".5",
    padding: "1rem",
    fontSize: "1.5rem",
    fontWeight: "500",
    lineHeight: "1",
    textShadow: "0 1px 0 #fff",
    backgroundColor: "transparent",
    "&:hover": {
      opacity: 1,
    },
  },

  modalBody: {
    paddingTop: "24px",
    paddingRight: "24px",
    paddingBottom: "16px",
    paddingLeft: "24px",
    position: "relative",
    overflow: "visible",
  },
  modalFooter: {
    padding: "15px",
    textAlign: "center",
    paddingTop: "0",
    margin: "0",
  },

  modalClose: {
    width: "24px",
    height: "24px",
  },

  howToTitle: {
    marginLeft: "25px",
    fontFamily: "Roboto",
    fontSize: "12px",
  },

  howToInput: {
    marginLeft: "25px",
    marginTop: "10px",
    width: "400px",
    height: "30px",
    backgroundColor: "#131A22 !important",
    color: whiteColor,
  },

  switchBase: {
    color: primaryColor[0] + "!important",
    "&:hover": {
      backgroundColor:
        "rgba(" + hexToRgb(primaryColor[0]) + ", 0.14) !important",
    },
  },

  switchIcon: {
    boxShadow: "0 1px 3px 1px rgba(" + hexToRgb(blackColor) + ", 0.4)",
    color: whiteColor + "  !important",
    border: "1px solid rgba(" + hexToRgb(blackColor) + ", .54)",
  },

  switchIconChecked: {
    borderColor: primaryColor[0],
    transform: "translateX(0px)!important",
  },

  switchBar: {
    width: "30px",
    height: "15px",
    backgroundColor: "rgb(" + hexToRgb(grayColor[24]) + ")",
    borderRadius: "15px",
    opacity: "0.7!important",
  },

  switchChecked: {
    "& + $switchBar": {
      backgroundColor: "rgba(" + hexToRgb(primaryColor[0]) + ", 1) !important",
    },
    "& $switchIcon": {
      borderColor: primaryColor[0],
    },
  },

  labelRoot: {
    marginLeft: "-14px",
    marginRight: "-16px",
  },

  contentPanel: {
    marginLeft: "25px",
    marginTop: "20px",
    width: "400px",
    backgroundColor: "#131A22 !important",
    padding: "0px !important",
    borderBottomColor: "#131A22 !important",
    borderBottomWidth: "1px !important",
    color: "white !important",
  },

  inputColor: {
    color: "white",
  },

  copyBtn: {
    marginTop: "10px",
    width: "400px",
    height: "50px",
    fontSize: "20px",
    color: "white",
    boxShadow:
      "0 2px 2px 0 rgb(37 95 208 / 14%), 0 3px 1px -2px rgb(37 95 208 / 20%), 0 1px 5px 0 rgb(37 95 208 / 12%)",
    backgroundColor: "#255FD0",
    margin: "0",
    "&:hover": {
      zIndex: "2",
      boxShadow:
        "0 14px 26px -12px rgb(37 95 208 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(37 95 208 / 20%)",
      backgroundColor: "#255FD0",
    },
  },

  dismissBtn: {
    marginTop: "10px",
    width: "400px",
    height: "50px",
    fontSize: "20px",
    color: "white",
    boxShadow:
      "0 2px 2px 0 rgb(37 95 208 / 14%), 0 3px 1px -2px rgb(37 95 208 / 20%), 0 1px 5px 0 rgb(37 95 208 / 12%)",
    backgroundColor: "#255FD0",
    margin: "0",
    "&:hover": {
      zIndex: "2",
      boxShadow:
        "0 14px 26px -12px rgb(37 95 208 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(37 95 208 / 20%)",
      backgroundColor: "#255FD0",
    },
  },
});

export default learnerExperienceStyle;
