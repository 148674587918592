import React, { useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Box,
  Card,
  Grid,
  Link,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useMoralis } from "react-moralis";
import swal from "sweetalert";
import LoadingButton from "@mui/lab/LoadingButton";
import { navigate } from "@reach/router";
import BG1 from "../../assets/images/bg-1.svg";
import BG2 from "../../assets/images/bg-2.svg";
import facebook from "../../assets/socialMedia/Facebook.png";
import instagram from "../../assets/socialMedia/Instagram.svg";

const WalletSignUp = () => {
  const {
    signup,
    isAuthenticated,
    authError,
    isAuthenticating,
    // Moralis
  } = useMoralis();

  // Form validation with formik/Yup
  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email format")
      .required("email is required"),
    password: yup.string().required("Required Password"),
  });

  const onSubmit = async (values) => {
    const email = values.email;
    const password = values.password;

    await signup(email, password);
    const emailVerification = await Moralis.User.requestEmailVerification(
      email
    );
    await Moralis.Cloud.run("sendVerification", { email, emailVerification });
    formik.resetForm();
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  useEffect(() => {
    if (authError) {
      swal({
        title: authError.name,
        text: authError.message,
        icon: "warning",
        dangerMode: true,
      });
    }

    if (isAuthenticated) {
      navigate("/connectWallet");
    }
  }, [authError, isAuthenticated]);
  return (
    <Box
      sx={{
        paddingY: "10%",
        backgroundImage: `url(${BG1}), url(${BG2})`,
        backgroundSize: "cover",
      }}
    >
      <Container fixed>
        <Grid container>
          <Grid item xs={12} sm={1} md={3}></Grid>
          <Grid item xs={12} sm={10} md={6}>
            <Card
              sx={{
                borderRadius: "20px",
                backgroundColor: "white",
                padding: "8%",
                mx: "5%",
                boxShadow: "1px 5px 8px 7px #ececec",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "space-between",
                }}
              >
                <img
                  style={{ height: "40px" }}
                  className="facebook"
                  src={facebook}
                  alt="Facebook"
                />
                <Box sx={{ textAlign: "center", flex: "1" }}>
                  <Typography variant="h4">Sign Up</Typography>
                  <Box sx={{ marginTop: "4%" }}>
                    <Typography variant="p" sx={{ fontWeight: "500" }}>
                      Already have an account?
                    </Typography>
                    <Link
                      href="/walletSignin"
                      sx={{
                        textDecoration: "none",
                        color: "#eb1f24",
                        fontWeight: "500",
                        marginLeft: "1%",
                      }}
                    >
                      Sign In
                    </Link>
                  </Box>
                </Box>
                <img
                  style={{ width: "40px" }}
                  className="instagram"
                  src={instagram}
                  alt="Instagram"
                />
              </Box>

              <Box component="form" onSubmit={formik.handleSubmit}>
                <Box>
                  <Typography
                    align="left"
                    sx={{
                      marginTop: "25px",
                      fontSize: {
                        md: "16px",
                        lg: "18px",
                        xs: "16px",
                        sm: "16px",
                      },
                      fontWeight: "bolder",
                      marginBottom: "2%",
                    }}
                  >
                    Email
                  </Typography>

                  <TextField
                    name="email"
                    formik={formik}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    variant="standard"
                    autoComplete="off"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    fullWidth
                    type="email"
                    placeholder="Enter Email"
                    sx={{
                      ".MuiInput-root": {
                        // backgroundColor: "rgb(231 227 227 )",
                        borderRadius: "8px",
                        border: "1px solid gray",
                        px: "20px",
                        py: "6px",
                        // fontWeight: "bold"
                      },
                      ".Mui-error": {
                        fontSize: "16px",
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    align="left"
                    sx={{
                      marginTop: "10%",
                      fontSize: {
                        md: "16px",
                        lg: "18px",
                        xs: "16px",
                        sm: "16px",
                      },
                      fontWeight: "bolder",
                      marginBottom: "2%",
                    }}
                  >
                    Password
                  </Typography>

                  <TextField
                    name="password"
                    formik={formik}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    variant="standard"
                    autoComplete="off"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    fullWidth
                    type="password"
                    placeholder="Password"
                    sx={{
                      ".MuiInput-root": {
                        borderRadius: "8px",
                        border: "1px solid gray",
                        px: "20px",
                        py: "6px",
                      },
                      ".Mui-error": {
                        fontSize: "16px",
                      },
                    }}
                  />
                  <LoadingButton
                    loading={isAuthenticating}
                    type="submit"
                    variant="contained"
                    sx={{
                      marginTop: "10%",
                      fontSize: {
                        lg: "20px",
                        md: "20px",
                        sm: "20px",
                        xs: "12px",
                      },
                      borderRadius: "8px",

                      height: {
                        lg: "50px",
                        md: "50px",
                        sm: "50px",
                        xs: "40px",
                      },
                      color: "white",
                      backgroundColor: "#eb1f24",
                    }}
                    fullWidth
                  >
                    Sign Up
                  </LoadingButton>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={1} md={3}></Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default WalletSignUp;
