import React from "react";
import Container from "@mui/material/Container";

import { LatestNewsWrap, LatestNewsHeading, BordersWrap } from "./style";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import CustomForm from "./../CustomForm";
const LatestNews = () => {
  const postUrl =
    "https://lillup.us19.list-manage.com/subscribe/post?u=83ea2f5575e5618ac92c99092&id=64790f07aa";

  return (
    <LatestNewsWrap>
      <Container fixed>
        <LatestNewsHeading>
          <h1>STAY UPDATED ON THE LATEST NEWS</h1>
          <BordersWrap>
            <span />
            <span />
            <span />
          </BordersWrap>
        </LatestNewsHeading>
        <MailchimpSubscribe
          url={postUrl}
          render={(props) => {
            const { subscribe, status, message } = props || {};
            return (
              <CustomForm
                status={status}
                message={message}
                onValidated={(formData) => subscribe(formData)}
              />
            );
          }}
        />
      </Container>
    </LatestNewsWrap>
  );
};

export default LatestNews;
