import styled from "styled-components";

export const TwitterLinkSection = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
`;

export const TwitterSlider = styled.div`
  width: 100vw;
  position: relative;

  .swiper-slide {
    max-width: 400px;
  }

  .twitter-tweet {
    max-width: 400px;

    iframe {
      width: 100%;
      height: 100%;
      max-width: 400px;
    }
  }
`;
