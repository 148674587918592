import React, { memo, useState, forwardRef } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  InputAdornment,
  OutlinedInput,
  Slide,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import learnerExperienceStyle from "./style";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(learnerExperienceStyle);
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const EmbeddedGroup = (props) => {
  const pageClasses = useStyles();

  const [embeddedPanel, setEmbeddedPanel] = useState(false);
  const [checkedA, setCheckedA] = useState(true);
  return (
    <Box className={pageClasses.embeddedGroup}>
      <Button
        className={pageClasses.embeddedBtn}
        onClick={() => setEmbeddedPanel(true)}
      >
        EMBEDDED
      </Button>
      <Dialog
        classes={{
          paper: pageClasses.dialog,
        }}
        open={embeddedPanel}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setEmbeddedPanel(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={pageClasses.modalHeader}
        >
          <span className={pageClasses.titleEmbed}>
            Embed maps in your site
          </span>
          <Button
            simple
            className={pageClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => setEmbeddedPanel(false)}
            sx={{ color: "#999" }}
          >
            <Close className={pageClasses.modalClose} />
          </Button>
        </DialogTitle>
        <DialogContent className={pageClasses.modalBody}>
          <span className={pageClasses.howToTitle}>
            Learn more about how to embed the logic{" "}
            <a
              className={pageClasses.fontStyle}
              href="https://www.creative-tim.com/?ref=mkpr-javascript-components"
            >
              here
            </a>
          </span>
          <Box>
            <OutlinedInput
              className={pageClasses.howToInput}
              type={"text"}
              endAdornment={
                <InputAdornment position="end">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={checkedA}
                        onChange={(event) => setCheckedA(event.target.checked)}
                        value="checkedA"
                        classes={{
                          switchBase: pageClasses.switchBase,
                          checked: pageClasses.switchChecked,
                          thumb: pageClasses.switchIcon,
                          track: pageClasses.switchBar,
                        }}
                      />
                    }
                    classes={{
                      root: pageClasses.labelRoot,
                    }}
                    label={"false"}
                  />
                </InputAdornment>
              }
              value={checkedA ? "iFrame" : "Web component"}
            />
          </Box>
          <TextField
            id="textarea-input-embedded"
            inputProps={{ className: pageClasses.inputColor }}
            multiline
            rows={7}
            variant="filled"
            sx={{ width: "100%", color: "white" }}
            className={pageClasses.contentPanel}
          />
        </DialogContent>
        <DialogActions className={pageClasses.modalFooter}>
          <Box>
            <Button link className={pageClasses.copyBtn}>
              Copy
            </Button>
            <Button
              onClick={() => setEmbeddedPanel(false)}
              simple
              className={pageClasses.dismissBtn}
            >
              Dismiss
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default memo(EmbeddedGroup);
