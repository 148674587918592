import { React, memo } from "react";
import { ImageList, Box, Button, Typography, Card } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NFTTokenPanel from "./NFTTokenPanel";
import { makeStyles } from "@material-ui/core/styles";
import Bar_Std from "./Bar_Std";
import PropTypes from "prop-types";
import learnerExperienceStyle from "./style";

const useStyles = makeStyles(learnerExperienceStyle);

const NFTTokenDetail = (props) => {
  const pageClasses = useStyles();

  const onBack = function () {
    if (props.onBackToPortfolio) {
      props.onBackToPortfolio();
    }
  };

  return (
    <Box
      id="nft-token-detail-wrapper"
      className={
        pageClasses.nftTokenDetailWrapper +
        " " +
        pageClasses.nftTokenWrapper_hidden
      }
    >
      <Box className={pageClasses.tokenCodeGrp}>
        <Box className={pageClasses.buttonGroup}>
          <Button
            sx={{
              backgroundColor: "#eb1f24",
              "&:hover": {
                zIndex: "2",
                boxShadow:
                  "0 14px 26px -12px rgb(235 31 36 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(235 31 36 / 20%)",
                backgroundColor: "#eb1f24",
              },
            }}
            onClick={onBack}
          >
            <ArrowBackIcon sx={{ color: "white" }} />
          </Button>

          <ImageList
            sx={{
              transform: "translateZ(0)",
              overflow: "unset",
            }}
            variant="quilted"
            cols={1}
            rowHeight={480}
            gap={0}
          >
            <NFTTokenPanel
              key={1}
              index={1}
              img={props.metadata.image}
              title={props.metadata.title}
              description={props.metadata.desc}
              cols={props.metadata.col || 1}
              rows={props.metadata.row || 1}
              globalIndex={props.metadata.globalIndex}
              onSelected={null}
            />
          </ImageList>
        </Box>
      </Box>
      <Bar_Std />
    </Box>
  );
};

export default memo(NFTTokenDetail);

NFTTokenDetail.propTypes = {
  metadata: PropTypes.object.isRequired,
  onBackToPortfolio: PropTypes.func.isRequired,
};

NFTTokenDetail.defaultProps = {
  metadata: {},
  onBackToPortfolio: null,
};
