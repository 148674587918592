import React, { useState } from "react";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { NewsLetter, Form } from "./style";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import { decode } from "html-entities";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 4,
  width: 30,
  height: 30,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5"
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)"
  }
}));

// const BpCheckedIcon = styled(BpIcon)({
//   backgroundColor: "#137cbd",
//   backgroundImage:
//     "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
//   "&:before": {
//     display: "block",
//     width: 30,
//     height: 30,
//     backgroundImage:
//       "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
//       " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
//       "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
//     content: '""'
//   },
//   "input:hover ~ &": {
//     backgroundColor: "#106ba3"
//   }
// });

const CustomForm = ({ status, message, onValidated }) => {
  // const { modalOpen, setModalOpen } = useGHStContext();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  /**
   * Handle form submit.
   *
   * @return {{value}|*|boolean|null}
   */
  const handleSubmit = () => {
    setError(null);

    if (!email) {
      setError("Please enter a valid email address");
      return null;
    }

    const isFormValidated = onValidated({ EMAIL: email });

    // On success return true
    return email && email.indexOf("@") > -1 && isFormValidated;
  };
  /**
   * Handle Input Key Event.
   *
   * @param event
   */
  const handleInputKeyEvent = (event) => {
    setError(null);

    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      handleSubmit();
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  /**
   * Extract message from string.
   *
   * @param {String} message
   * @return {null|*}
   */
  const getMessage = (message) => {
    if (!message) {
      return null;
    }
    const result = message?.split("-") ?? null;
    if (result?.[0]?.trim() !== "0") {
      return decode(message);
    }
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? decode(formattedMessage) : null;
  };

  return (
    <NewsLetter>
      <Form>
        <Grid
          container
          alignItems="center"
          rowSpacing={0}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={9}>
            <Input
              onChange={(event) => handleChange(event)}
              type="email"
              value={email}
              onKeyUp={(event) => handleInputKeyEvent(event)}
            />
          </Grid>
          <Grid item xs={3}>
            <Button className="submit" onClick={handleSubmit}>
              SIGNUP
            </Button>
          </Grid>
        </Grid>
        {/* <Hidden>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} />
              }
              label="Yes, I agree to receive emails communication from lillup"
            />
          </FormGroup>
        </Hidden> */}
      </Form>
      <div className="newsletter-form-info">
        {status === "sending" && <div>Sending...</div>}
        {status === "error" || error ? (
          <div
            className="newsletter-form-error error"
            dangerouslySetInnerHTML={{ __html: error || getMessage(message) }}
          />
        ) : null}
        {/* {status === "success" && status !== "error" && !error && (
          <div
            className="success"
            dangerouslySetInnerHTML={{ __html: decode(message) }}
          />
        )} */}
      </div>
    </NewsLetter>
  );
};
export default CustomForm;
