import { makeStyles } from '@material-ui/core';

import ProfileForm from '../ProfileComponents/ProfileForm';

import mediaStyle from '../ProfileComponents/style/profileStyle';
import Navigate from '../SideNavigate/Navigate';

const useStyles = makeStyles(mediaStyle);

export default function ProfileHolder() {
	const classes = useStyles();

	return (
		<div className={classes.mainContainer}>
			<Navigate />
			<ProfileForm />
		</div>
	);
}
