import { makeStyles } from "@mui/styles";
import videoStyle from "./style.js";
import ModalVideo from "react-modal-video";
const useStyles = makeStyles(videoStyle);
const Video = () => {
  const classes = useStyles();
  return (
    <>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="r8DhNCY5Piw"
        onClose={() => setOpen(false)}
      />
      {/* <div className={classes.playerWrapper}>
      <div className={classes.videoResponsive}>
        <iframe
          className={classes.iframe}
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/r8DhNCY5Piw?rel=0`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </div> */}
    </>
  );
};
export default Video;
