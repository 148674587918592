import React, { useState, useEffect } from "react";
import { Container, Box, Typography, Grid, IconButton } from "@mui/material";
import { Link } from "@reach/router";
import { TwitterLinkSection, TwitterSlider } from "./style";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Tweet } from "react-twitter-widgets";
import axios from "axios";

export default function TwitterSection() {
  const [tweet, setTweet] = useState({});

  const fetchTweets = async () => {
    const response = await axios.get(
      "https://bz646znfyi.execute-api.us-east-1.amazonaws.com/dev/"
    );

    const res = JSON.parse(response.data.body);

    setTweet(res[0]);
  };

  useEffect(() => {
    fetchTweets();
  }, []);

  return (
    <Box
      sx={{
        paddingY: "10%",
        backgroundColor: `#55ACEE`,
        padding: "57px 0",
        marginBottom: "203px",
        position: "relative",
      }}
      id="twitter"
    >
      <Container fixed>
        <Box
          sx={{
            textAlign: "center",
            marginBottom: "35px",
            height: "255px",
          }}
        >
          <Box sx={{ position: "relative" }}>
            <TwitterLinkSection>
              <Link to="/#twitter">
                <IconButton
                  aria-label="Example"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      "https://www.lillup.com/twitter"
                    );
                  }}
                >
                  <BsThreeDotsVertical size={26} color="white" />
                </IconButton>
              </Link>
            </TwitterLinkSection>
          </Box>
          <Typography
            variant="h2"
            sx={{
              color: "white",
              fontSize: "26px",
              fontWeight: "700",
              lineHeight: "32.5px",
              textTransform: "uppercase",

              "@media(min-width: 780px)": {
                fontSize: "36px",
                lineHeight: "42.5px",
              },
            }}
          >
            &Chain Protocol brings who you are
          </Typography>
          <Box>
            <Typography
              variant="span"
              sx={{
                height: "4px",
                display: "inline-block",
                width: "30px",
                background: "#fff",
                marginTop: "21px",
              }}
            ></Typography>
            <Typography
              variant="span"
              sx={{
                height: "4px",
                display: "inline-block",
                width: "30px",
                background: "#eb1f24",
              }}
            ></Typography>
            <Typography
              variant="span"
              sx={{
                height: "4px",
                display: "inline-block",
                width: "30px",
                background: "#2f1a31",
              }}
            ></Typography>
          </Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            boxShadow: "0px 18px 31px rgba(203, 203, 203, 0.25)",
            borderRadius: "6px",
            left: "50%",
            textAlign: "center",
            maxHeight: "333px",
            width: "420px",
            top: "165px",
            transform: "translateX(-50%)",

            "@media(max-width: 780px)": {
              left: "50%",
              right: "0",
              transform: "translateX(-50%)",
              maxWidth: "300px",
            },
          }}
        >
          <Tweet
            tweetId={tweet.id_str}
            options={{
              cards: "hidden",
            }}
          />
        </Box>
      </Container>
    </Box>
  );
}
