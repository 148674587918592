import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import facebook from "../../assets/socialMedia/Facebook.png";
import instagram from "../../assets/socialMedia/Instagram.svg";
import linkedin from "../../assets/socialMedia/linkedin.svg";
import twitter from "../../assets/socialMedia/twitterNew.svg";

import { SocialMediaWrap } from "./style";

export default function SocialMediaSection() {
  return (
    <SocialMediaWrap>
      <Container fixed>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0",
            "@media(min-width: 780px)": {
              padding: "0 20%",
            },
          }}
        >
          <a target="_blank" href="https://www.facebook.com/LillupWorkplace/">
            <img className="facebook" src={facebook} alt="Facebook" />
          </a>
          <a target="_blank" href="https://www.linkedin.com/company/lillup">
            <img className="linkedin" src={linkedin} alt="Linkedin" />
          </a>
          <a target="_blank" href="https://twitter.com/LillupWorkplace">
            <img className="twitter" src={twitter} alt="Twitter" />
          </a>
          <a target="_blank" href="https://www.instagram.com/lillup_ampersand/">
            <img className="instagram" src={instagram} alt="Instagram" />
          </a>
        </Box>
      </Container>
    </SocialMediaWrap>
  );
}
