import React, { useEffect } from "react";
import BasicModal from "../components/BasicModal";
// import EmptyComponent from "../components/EmptyComponent/EmptyComponent";
import TotalValueAssetsHolder from "../components/TotalValueAssetsHolder/TotalValueAssetsHolder";

function TotalValueAssets() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="creator">
      {/* <BasicModal /> */}
      <TotalValueAssetsHolder />
    </div>
  );
}

export default TotalValueAssets;
