import React, { useState, useEffect } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core';
import CreateForm from '../CreateComponents/CreateForm';
import Navigate from '../SideNavigate/Navigate';
import createHolderStyle from '../CreateComponents/style/createHolderStyle.js';
import Web3 from 'web3'

const useStyles = makeStyles(createHolderStyle);

const chainId = 80001; // Mumbai Testnet

const web3 = new Web3(Web3.givenProvider);

export default function CreateHolder() {
	const getChainId = async () => {
		if (window.ethereum.networkVersion !== chainId) {
			try {
				await window.ethereum
					.request({
						method: 'wallet_switchEthereumChain',
						params: [ { chainId: web3.utils.toHex(chainId) } ]
					})
					.then((res) => {
						console.log(res);
						console.log('change network id');
					});
			} catch (err) {
				// This error code indicates that the chain has not been added to MetaMask
				console.log(err);
        alert("Please connect wallet and change chain Polygon");
			}
		}
	};

	useEffect(() => {
		getChainId();
	}, []);

	const classes = useStyles();

	return (
		<div className={classes.mainContainer}>
			<Navigate />
			<CreateForm />
		</div>
	);
}
