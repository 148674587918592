import React, { useEffect } from "react";
// import EmptyComponent from "../components/EmptyComponent/EmptyComponent";
import AboutResource from "../components/AboutResource/AboutResource";
import ResourceService from "../components/ResoucesService";
import LatestNews from "../components/LatestNews";

function Resource() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AboutResource />
      <ResourceService />
      <LatestNews />
    </>
  );
}

export default Resource;
