import React from "react";

import { makeStyles } from "@mui/styles";

import Button from "@mui/material/Button";

const useStyles = makeStyles(() => ({
  button: {
    minHeight: "auto",
    minWidth: "auto",
    backgroundColor: "#e52d27",
    color: "white",
    border: "none",
    borderRadius: "10px",
    padding: "10px 30px",
    marginLeft: "25%",
    marginBottom: "30px",
    fontSize: "18px",
    fontWeight: "500",
    fontFamily: "Roboto, sans-serif",
    textTransform: "uppercase",
    letterSpacing: "0",
    textAlign: "center",
    whiteSpace: "nowrap",
    cursor: "pointer",
    width: "50%",
    "&:hover,&:focus": {
      color: "white",
      backgroundColor: "#e52d27"
    }
  }
}));

const ChainButton = (props) => {
  const { children, ...rest } = props;
  const classes = useStyles();

  return (
    <Button {...rest} href={props.email} className={classes.button}>
      {children}
    </Button>
  );
};

export default ChainButton;
