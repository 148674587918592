import React from "react";
import {
  Container,
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";


import { AboutWrap, AboutTextWrap, AboutTextHeading, AboutText } from "./style";
import CircleIcon from "@mui/icons-material/Circle";
const AboutCreator = () => {
  return (
    <AboutWrap>
      <Container fixed>
        <Grid container alignItems="top" spacing={4}>
          <Grid item xs={12} md={6} lg={7.5}>
            <AboutTextWrap>
              <AboutTextHeading>
                Change the way <br /> Human Capital is valued
              </AboutTextHeading>
              <AboutText>
                &chain protocol enhances Human Experience for the Future of Work
              </AboutText>
              <Box>
                <List>
                  <ListItem disablePadding>
                    <ListItemIcon sx={{ minWidth: "30px" }}>
                      <CircleIcon sx={{ width: "0.5em", hieght: "0.5em" }} />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        fontSize: "24px",
                        lineHeight: "28px",
                        color: "#777777"
                      }}
                      primary="New asset class regarding the Human Capital value"
                    />
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemIcon sx={{ minWidth: "30px" }}>
                      <CircleIcon sx={{ width: "0.5em", hieght: "0.5em" }} />
                    </ListItemIcon>
                    <ListItemText primary="Learner loyalty" />
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemIcon sx={{ minWidth: "30px" }}>
                      <CircleIcon sx={{ width: "0.5em", hieght: "0.5em" }} />
                    </ListItemIcon>
                    <ListItemText primary="Monetization for all stakeholders" />
                  </ListItem>
                </List>
              </Box>
            </AboutTextWrap>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={4.5}
            justifySelf={"center"}
            sx={{ textAlign: "center" }}
          >
            <img src="images/AboutCreator.svg" alt="Lillup" />
          </Grid>
        </Grid>
      </Container>
    </AboutWrap>
  );
};

export default AboutCreator;
