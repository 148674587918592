import styled from "styled-components";

export const LatestNewsWrap = styled.section`
  padding: 53px 3.3% 150px;
  .MuiFormGroup-root {
    align-items: center;
    .MuiTypography-root {
      font-style: normal;
      font-weight: 300;
      font-size: 22px;
      line-height: 26px;
      color: #b9b9b9;
      margin-left: 8px;
      label {
        margin: 0;
      }
    }
  }
  @media (max-width: 1199.9px) {
    padding-bottom: 100px;
    .MuiFormGroup-root {
      .MuiTypography-root {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  @media (max-width: 599.9px) {
    padding-bottom: 60px;
  }
`;

export const LatestNewsHeading = styled.div`
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    text-transform: uppercase;
    color: #5a4a42;
    text-align: center;
    margin: 0;
    margin-bottom: 11px;
  }

  @media (max-width: 1199.9px) {
    h1 {
      font-size: 26px;
      line-height: 32px;
    }
  }

  @media (max-width: 599.9px) {
    h1 {
      font-size: 22px;
      line-height: 28px;
    }
  }
`;

export const BordersWrap = styled.div`
  text-align: center;
  span {
    height: 4px;
    display: inline-block;
    width: 30px;
  }
  span:nth-child(1) {
    background: #ababab;
  }

  span:nth-child(2) {
    background: #eb1f24;
  }

  span:nth-child(3) {
    background: #2f1a31;
  }
`;

export const NewsLetter = styled.div`
  width: 820px;
  height: 72px;
  background: #f5f5f5;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 6px 5px;
  margin: 64px auto 27px;
  @media (max-width: 1199.9px) {
    width: 100%;
    height: 60px;
  }
`;

export const Form = styled.form`
  .MuiInputBase-root {
    width: 100%;
    height: 60px;
    &:before {
      content: unset;
    }
    &:after {
      content: unset;
    }
  }
  .submit {
    width: 100%;
    height: 60px;
    background: #eb1f24;
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #ffffff;
    &:hover {
      background-color: #eb1f24;
    }
  }

  @media (max-width: 1199.9px) {
    .MuiInputBase-root {
      height: 40px;
    }

    .submit {
      height: 40px;
      border-radius: 8px;
      font-size: 16px;
      line-height: 20px;
    }
  }
`;
