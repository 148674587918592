import React, { useContext, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  Grid,
  CardMedia
} from "@mui/material";
import { AppContext } from "../utils/context";
import { useMoralis } from "react-moralis";
import swal from "sweetalert";
// import LoadingButton from "@mui/lab/LoadingButton";
import { navigate } from "@reach/router";


function ConnectWallet() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { enableWeb3, isWeb3Enabled, web3EnableError } = useMoralis();
  const { locationPath } = useContext(AppContext);

  const connectMetaMask = async () => {
    await enableWeb3();
  };

  if (web3EnableError) {
    swal({
      title: web3EnableError.name,
      text: web3EnableError.message,
      icon: "warning",
      dangerMode: true
    });
  }

  if (isWeb3Enabled) {
    swal({
      title: "Connected",
      text: "Succefully Connected",
      icon: "success",
      dangerMode: false
    });
    navigate(locationPath);
  }
  // console.log(web3EnableError, isWeb3Enabled);
  return (
    <>
      <Box
        sx={{
          paddingY: "10%",
          backgroundImage: "url(images/bg-1.svg), url(images/bg-2.svg)",
          backgroundSize: "cover"
        }}
      >
        <Container fixed>
          <Grid container>
            <Grid item xs={12} sm={1} md={3}></Grid>
            <Grid item xs={12} sm={10} md={6}>
              <Card
                sx={{
                  borderRadius: "10px",
                  backgroundColor: "white",
                  padding: "8%",
                  mx: "5%",
                  boxShadow: "1px 5px 8px 7px #ececec",
                  textAlign: "center"
                }}
              >
                <Typography variant="h5" sx={{ marginBottom: "2%" }}>
                  Connect your MetaMask wallet
                </Typography>

                <Card
                  sx={{
                    borderRadius: "10px",
                    backgroundColor: "white",
                    padding: "4%",
                    boxShadow: "1px 5px 8px 7px #ececec",
                    textAlign: "center",
                    width: "30%",
                    margin: "0 auto",
                    my: "5%"
                  }}
                >
                  {/* <LoadingButton
                    loading={isWeb3EnableLoading}
                    onClick={connectMetaMask}
                    className="myWallet login"
                  > */}
                  <CardMedia
                    component="img"
                    height="60"
                    image="images/wallet-icon.svg"
                    alt="Meta Mask"
                  />
                  {/* </LoadingButton> */}
                </Card>
              </Card>
            </Grid>
            <Grid item xs={12} sm={1} md={3}></Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default ConnectWallet;
