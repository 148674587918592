import React, { useState } from "react";
// @material-ui/core components
import { makeStyles, Switch } from "@material-ui/core";

import bodyProfileStyle from "./style/bodyProfileStyle";

const useStyles = makeStyles(bodyProfileStyle);

export default function BodyProfile({ setFormData, formData }) {
  const classes = useStyles();
  const [switched, setSwitched] = useState(false);
  return (
    <div className={classes.body_grid}>
      <div>
        <h3 className={classes.body_title}>Custodian Information</h3>
        <div>
          <label>Full Name</label>
          <input
            value={formData.full_name}
            className={classes.body_input}
            placeholder="first name and last name"
            required
            onChange={(e) =>
              setFormData({ ...formData, full_name: e.target.value })
            }
          />
        </div>
        <div>
          <label>Email</label>
          <input
            value={formData.email}
            className={classes.body_input}
            placeholder="creator email"
            required
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />
        </div>
        <div>
          <label>Position</label>
          <input
            value={formData.position}
            className={classes.body_input}
            placeholder="position"
            required
            onChange={(e) =>
              setFormData({ ...formData, position: e.target.value })
            }
          />
        </div>
      </div>
      <div>
        <div className={classes.switch_section}>
          <span className={switched === false && classes.switch_color}>
            Company
          </span>
          <Switch
            value={formData.company}
            onClick={() => {
              setSwitched(!switched);
              setFormData({ ...formData, company: !switched });
            }}
            style={{ color: "red" }}
          />
          <span className={switched === true && classes.switch_color}>
            Not-for-profit
          </span>
        </div>

        <div>
          <label>Name</label>
          <input
            value={formData.name}
            className={classes.body_input}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            placeholder="name"
            required
          />
        </div>
        <div>
          <label>Address </label>
          <input
            value={formData.address}
            className={classes.body_input}
            placeholder="street, postcode, city, country"
            required
            onChange={(e) =>
              setFormData({ ...formData, address: e.target.value })
            }
          />
        </div>
        <div>
          <label>Website</label>
          <input
            value={formData.website}
            className={classes.body_input}
            placeholder="www.example.com"
            required
            onChange={(e) =>
              setFormData({ ...formData, website: e.target.value })
            }
          />
        </div>
      </div>
    </div>
  );
}
